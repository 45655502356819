import { hideHelpScoutBeacon, showHelpScoutBeacon } from "@/core";
import { Component } from "vue";
import { VuexModule } from "../store";

type ModalComponent = Component | (() => Promise<Component>) | undefined;

interface ModalState {
  show: boolean;
  component: ModalComponent;
  hideCloseButton: boolean;
}

const modal: VuexModule<ModalState, undefined> = {
  namespaced: true,
  state: (): ModalState => ({
    show: false,
    component: undefined,
    hideCloseButton: false,
  }),
  mutations: {
    setShow(state: ModalState, val: boolean): void {
      removeBodyScroll(val);
      if (val) {
        hideHelpScoutBeacon();
      } else {
        showHelpScoutBeacon();
      }
      state.show = val;
    },
    setComponent(state: ModalState, val: ModalComponent): void {
      state.component = val;
    },
    setHideCloseButton(state: ModalState, val: boolean): void {
      state.hideCloseButton = val;
    },
  },
  actions: {
    show({ commit }): void {
      commit("setShow", true);
    },
    toggle({ state, commit }): void {
      commit("setShow", !state.show);
    },
    hide({ commit }): void {
      commit("setShow", false);
    },
    close({ commit }): void {
      commit("setShow", false);
    },
    setShow({ commit }, val: boolean): void {
      commit("setShow", val);
    },
    setComponent({ commit }, val: ModalComponent): void {
      commit("setComponent", val);
    },
    clearComponent({ commit }): void {
      commit("setExperience", undefined);
    },
    setOptions({ commit }, options: { hideCloseButton?: boolean }): void {
      const { hideCloseButton } = options;
      commit("setHideCloseButton", hideCloseButton);
    },
  },
  getters: {},
};

const removeBodyScroll = (state: boolean) => {
  const bodyEl = document.getElementsByTagName("body")[0];
  if (state) {
    bodyEl.classList.add("overflow-hidden");
  } else {
    bodyEl.classList.remove("overflow-hidden");
  }
};

export { modal };
