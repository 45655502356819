import Vue from "vue";
import Vuex from "vuex";
import * as modules from "./modules";
Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ...(modules as any),
  },
});
