import { ResetPasswordRepository } from "./repositories/ResetPasswordRepository";

export const ForgotPasswordUseCases = {
  async sendSMS(emailOrPhone: string): Promise<void> {
    try {
      const res = await ResetPasswordRepository.post("step1", {
        emailOrPhone: emailOrPhone.toLowerCase(),
      });
      if (res.status !== 201) {
        throw "error";
      }
    } catch (error) {
      console.error(error);
    }
  },

  async validateSmsCode(
    emailOrPhone: string,
    phoneValidationCode: string
  ): Promise<void> {
    const res = await ResetPasswordRepository.post("step2", {
      emailOrPhone,
      phoneValidationCode,
    });
    if (res.data.codeValidated === false) {
      throw "wrong_code";
    }
  },
  async setNewPassword(
    emailOrPhone: string,
    phoneValidationCode: string,
    password: string
  ): Promise<void> {
    await ResetPasswordRepository.post("step3", {
      emailOrPhone,
      phoneValidationCode,
      password,
    });
  },
};
