import { getCurrencySymbol } from ".";
import { IAmount } from "../types";
import { formatNumber } from "./formatNumber";

export const formatAmount = ({ value, currency }: IAmount): string => {
  const formattedValue = formatNumber(value);

  if (currency) {
    const currencySymbol = getCurrencySymbol(currency);

    if (value > 1000000) {
      return formattedValue;
    } else {
      return formattedValue + currencySymbol;
    }
  } else {
    return formattedValue;
  }
};
