import Vue from "vue";
import { ValidationProvider, extend, ValidationObserver } from "vee-validate";
import { email, required, min } from "vee-validate/dist/rules";

const upCase = {
  validate: (value: string) => value.match(/[A-Z]/g) !== null,
  message: "errors.uppercase",
};

const number = {
  validate: (value: string) => value.match(/[0-9]/g) !== null,
  message: "errors.number",
};

extend("email", {
  ...email,
  message: "SignUp_Email_Format_Error",
});
extend("required", {
  ...required,
  message: "errors.required",
});
extend("min", {
  ...min,
  message: "errors.min",
});
extend("upCase", upCase);
extend("number", number);

Vue.component("ValidationProvider", ValidationProvider);
Vue.component("ValidationObserver", ValidationObserver);
