<template>
  <dl
    class="nn-detail-cell flex justify-between items-center pt-4 pb-2"
    :class="{
      'flex-col': col,
    }"
  >
    <dt class="" v-if="label">
      {{ $t(label) }}
    </dt>
    <dd>
      <slot />
    </dd>
  </dl>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    col: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped></style>
