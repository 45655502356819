




import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BaseSpinner extends Vue {
  @Prop({ type: Boolean, default: true })
  readonly white!: boolean;
  @Prop({ type: Boolean })
  readonly primary!: boolean;

  get classes(): Record<string, boolean> {
    return {
      "nn-spinner--white": this.white,
      "nn-spinner--primary": this.primary,
    };
  }
}
