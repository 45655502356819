import { RestRepository } from "../../base/RestRepository";
import defaultConfig from "../../config";

// http://svc-mobile-api.stg.internal.ninetynine.com/swagger-ui/index.html#/Clients/getClientAssetPositionUsingGET
const PositionRepository = new RestRepository({
  version: "v2",
  isPublic: false,
  path: "clients/position",
  baseURL: defaultConfig.api.url,
});

export { PositionRepository };
