import { RestRepository } from "../../base/RestRepository";
import defaultConfig from "../../config";

// http://svc-prices.stg.internal.ninetynine.com/swagger-ui/index.html#/Prices/getChartUsingGET
const PricesRepository = new RestRepository({
  version: "v1",
  isPublic: false,
  path: "prices",
  baseURL: defaultConfig.api.url,
});

export { PricesRepository };
