







import { Component, Vue } from "vue-property-decorator";
import LayoutProvider from "@/components/layout/LayoutProvider.vue";
@Component({
  components: {
    LayoutProvider,
  },
})
export default class App extends Vue {}
