import AxiosFactory, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from "axios";
import Config from "../types/Config";
import defaultConfig from "../config";
import { auth, localStorageKeys } from "..";

export interface RestRepositoryParams {
  version: string;
  path: string;
  baseURL: string;
  isPublic?: boolean;
  config?: Config;
  axios?: AxiosInstance;
}

export interface RestRepositoryHeaders {
  "x-user-agent": string;
  authorization?: string;
}

export class RestRepository {
  version = "v1";
  path: string;
  config: Config;
  isPublic: boolean;
  baseURL: string;
  axios: AxiosInstance;

  constructor({
    version,
    path,
    config,
    isPublic,
    baseURL,
    axios,
  }: RestRepositoryParams) {
    this.config = config || defaultConfig;
    this.version = version;
    this.path = path;
    this.isPublic = isPublic || false;
    this.baseURL = baseURL;
    this.axios =
      axios ||
      AxiosFactory.create({
        baseURL: this._URL,
        headers: {
          "x-user-agent": "0.0.1/web_v",
        },
      });

    this.loadInterceptors();
  }

  get _URL(): string {
    return `${this.baseURL}/${this.version}/${this.path}`;
  }

  public get(
    path: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return this.axios.get(path, config);
  }

  private loadInterceptors(): void {
    this.axios.interceptors.response.use(
      (res: AxiosResponse<unknown>) => {
        return res;
      },
      (error) => {
        const {
          response: {
            status,
            data: {
              message: { message },
            },
          },
        } = error;

        const authErrors = [
          "Jwt expired",
          "There is no session for this token.",
        ];

        if (authErrors.includes(message) && status === 401) {
          auth.logOut();
          window.localStorage.setItem(localStorageKeys.sessionExpired, "1");
          window.location.pathname = "/inicio-sesion";
        }
        throw error;
      }
    );

    this.axios.interceptors.request.use(
      (config: AxiosRequestConfig) => {
        if (!this.isPublic) {
          const token = auth.token;
          config.headers.authorization = token;
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );
  }

  public put(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return this.axios.put(url, data, config);
  }

  public post(
    url: string,
    data?: unknown,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return this.axios.post(url, data, config);
  }

  public delete(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse> {
    return this.axios.delete(url, config);
  }
}
