import configs, { APIConfig } from "./base";

const config: APIConfig = configs[process.env.NODE_ENV as string];
import pckg from "../../../package.json";

if (process.env.NODE_ENV === "development") {
  console.log("---------------------------------------");
  console.log(`Package: ${pckg.name}@${pckg.version}`);
  console.log(`Mode: ${process.env.NODE_ENV}`);
  console.log(`Config: `, config);
  console.log("---------------------------------------");
}
export default config;
