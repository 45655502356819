import { RestRepository } from "../../base/RestRepository";
import defaultConfig from "../../config";

const AssetsRepository = new RestRepository({
  version: "v1",
  isPublic: false,
  path: "assets",
  baseURL: defaultConfig.api.url,
});

export { AssetsRepository };
