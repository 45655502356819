/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-undef */
/* eslint-disable @typescript-eslint/explicit-module-boundary-types */

import { VueConstructor } from "vue/types/umd";
import { auth } from "..";

interface EventPayload {
  label: string;
  properties?: Record<string, string>;
}

const mode = process.env.NODE_ENV;

const isLocalhost = window.location.hostname === "localhost";

const debuglog = (...a: any) => {
  // console.log("🔧", ...a);
};

const win = window as any;

const lazyQueue = () => {
  const events: VoidFunction[] = [];
  let started = false;

  return {
    push: (event: VoidFunction) => {
      if (started) event();
      else events.push(event);
    },
    start: () => {
      started = true;

      while (events.length >= 1) {
        const ev = events.shift();
        ev && ev();
      }
    },
  };
};

const track = ({ properties, label }: EventPayload) => {
  // console.log("Event", { properties, label });
  try {
    win._cio?.track(label, properties);
    win.dataLayer.push({ event: label, ...properties });
    win.amplitude.getInstance().logEvent(label, properties);
  } catch (error) {
    // This error is not important
    // console.error(error);
  }
};

const queue = lazyQueue();

const install = async (Vue: VueConstructor) => {
  Vue.prototype.$track = (payload: EventPayload) => {
    queue.push(() => track(payload));
  };

  Vue.directive("track", {
    bind(el, { arg: event = "", value }, vnode) {
      const trackingObj = value;
      if (mode !== "production") {
        value.label = "STG_" + value.label;
      }

      if (vnode.componentInstance) {
        if (event === "view") {
          track(trackingObj);
        } else {
          vnode.componentInstance.$on(event, () => {
            track(trackingObj);
          });
        }
      } else {
        if (event === "view") {
          track(trackingObj);
        } else {
          el.addEventListener("click", () => track(trackingObj));
        }
      }
    },
    unbind(el, { arg: event = "" }, vnode) {
      if (vnode.componentInstance) {
        vnode.componentInstance.$off(event);
      }
    },
  });

  const loadScripts = async () => {
    require("./amplitude.js");
  };
  if (!isLocalhost) {
    await loadScripts();
    debuglog("loadScripts() finished");
  }

  queue.start();
};

export const identifyUser = (userId: string) => {
  try {
    win._cio?.identify({
      id: auth.clientId,
    });
    win.amplitude.getInstance().setUserId(userId);
    win.dataLayer.push({ event: "identifyUser", userId });
  } catch (error) {
    console.error(error);
  }
};

export const $track = (payload: EventPayload) => {
  queue.push(() => track(payload));
};

export default {
  install,
};
