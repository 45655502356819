import { getAssetURL } from "..";
import { IPosition } from "../types";
import {
  ClientsRepository,
  CollectionsRepository,
  PaymentsRepository,
} from "./repositories";

interface IAmount {
  currency: string;
  value: number;
}

interface IPortfolio {
  cashBalance: IAmount;
  clientId: string;
  positons: IPosition[];
  profit: {
    amount: IAmount;
    percentage: number;
  };
  totalAmount: IAmount;
  totalCurrentInvestedAmount: IAmount;
}
export interface PortfolioRes {
  availableMoney: {
    currency: string;
    value: number;
  };
  positions: [
    {
      assetId: string;
      companyName: string;
      currentInvestedAmount: {
        currency: string;
        value: number;
      };
      profit: {
        amount: {
          currency: string;
          value: number;
        };
        percentage: number;
      };
      shares: number;
      symbol: string;
    }
  ];
  totalCurrentInvestedAmount: {
    currency: string;
    value: number;
  };
  totalProfit: {
    amount: {
      currency: string;
      value: number;
    };
    percentage: number;
  };
}
export const PortfolioUseCases = {
  async getPortfolio(clientId: string): Promise<IPortfolio> {
    const { data } = await ClientsRepository.get("/portfolio", {
      params: {
        clientId,
      },
    });
    const assetsWithLogos = await Promise.all(
      data.positions.map(async (i: IPosition) => ({
        ...i,
        logoURL: await getAssetURL(i.asset.symbol),
      }))
    );
    return {
      ...data,
      positions: assetsWithLogos,
    };
  },
  async getFavs(clientId: string): Promise<unknown> {
    const { data } = await CollectionsRepository.get("/favorites", {
      params: {
        clientId,
        max: 100,
      },
    });

    const dataWithLogos = await Promise.all(
      data.map(async (i: any) => ({
        ...i,
        logoURL: await getAssetURL(i.symbol),
      }))
    );

    return dataWithLogos;
  },
  async setIBAN(clientId: string, iban: string): Promise<void> {
    await PaymentsRepository.post("/ibans", {
      clientId,
      iban,
    });
  },
  async getIBAN(clientId: string): Promise<unknown | undefined> {
    const res: any = await PaymentsRepository.get("/ibans", {
      params: {
        clientId,
      },
    });
    return res.data || "";
  },
};
