













import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BaseButton extends Vue {
  @Prop(Boolean)
  readonly primary!: boolean;
  @Prop(Boolean)
  readonly text!: boolean;
  @Prop(Boolean)
  readonly secondary!: boolean;
  @Prop(Boolean)
  readonly disabled!: boolean;
  @Prop(Boolean)
  readonly loading!: boolean;

  get userCanClick(): boolean {
    return !this.loading && !this.disabled;
  }

  get cssClasses(): Record<string, boolean> | string {
    if (this.text && this.disabled) {
      return "bg-none opacity-50 text-gray-400 hover:cursor-not-allowed";
    } else if (this.disabled) {
      return {
        "bg-gray-300 text-gray-500 opacity-50 hover:cursor-not-allowed": true,
      };
    } else {
      return {
        "bg-primary": this.primary,
        "text-white": this.primary,
        "hover:bg-primary-400": this.primary,

        "border-primary": this.secondary,
        "nn-border-info": this.secondary,

        "text-primary": this.secondary || this.text,
        "hover:bg-primary-50": this.secondary,

        "hover:cursor-not-allowed": this.loading,
        "hover:bg-primary": this.loading,
      };
    }
  }
}
