import { formatEmailOrPhone } from "@/core";
import { ForgotPasswordUseCases } from "@/core/ForgotPassword/ForgotPasswordUseCases";
import { VuexModule } from "../store";

interface ForgotPasswordState {
  emailOrPhone: string;
  smsCode: string;
  allowResendSMS: boolean;
}

interface ForgotPasswordGetters {
  formattedEmailOrPhone: string;
}

let TIMEOUT_ID = 0;

const forgotPassword: VuexModule<ForgotPasswordState, ForgotPasswordGetters> = {
  namespaced: true,
  state: (): ForgotPasswordState => ({
    emailOrPhone: "",
    smsCode: "",
    allowResendSMS: false,
  }),
  mutations: {
    SET_EOP(state, val) {
      state.emailOrPhone = val;
    },
    SET_SMSCODE(state, val) {
      state.smsCode = val;
    },
    SET_ALLOWRESENDSMS(state, val) {
      state.allowResendSMS = val;
    },
  },
  actions: {
    async sendSMS({ commit, dispatch }, emailOrPhone) {
      dispatch("startSMSCounter");
      await ForgotPasswordUseCases.sendSMS(emailOrPhone);
      commit("SET_EOP", emailOrPhone);
    },
    async setSMSCode({ commit, getters }, smsCode) {
      try {
        await ForgotPasswordUseCases.validateSmsCode(
          getters.formattedEmailOrPhone,
          smsCode
        );
        commit("SET_SMSCODE", smsCode);
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
    async resendSMS({ state, dispatch, getters }) {
      if (state.allowResendSMS) {
        await ForgotPasswordUseCases.sendSMS(getters.formattedEmailOrPhone);
        dispatch("startSMSCounter");
      }
    },
    startSMSCounter({ commit }) {
      const timeout = 30000;
      window.clearTimeout(TIMEOUT_ID);
      commit("SET_ALLOWRESENDSMS", false);
      const toggle = () => commit("SET_ALLOWRESENDSMS", true);
      TIMEOUT_ID = window.setTimeout(toggle, timeout);
    },
    async setNewPassword({ state }, newPassword: string): Promise<void> {
      try {
        await ForgotPasswordUseCases.setNewPassword(
          state.emailOrPhone,
          state.smsCode,
          newPassword
        );
      } catch (error) {
        console.error(error);
        throw error;
      }
    },
  },
  getters: {
    formattedEmailOrPhone(state): string {
      return formatEmailOrPhone(state.emailOrPhone);
    },
  },
};

export { forgotPassword };
