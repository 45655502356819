import { localStorageKeys } from "..";
import AxiosFactory from "axios";
import config from "../config";

interface IUser {
  id: string;
  clientId: string;
  status: string;
  statusUpdatedAt: string;
  email: string;
  phoneNumber: string;
  promotionalNotificationsAccepted: boolean;
  notificationsAccepted: boolean;
  dataCollectionAccepted: boolean;
  createdAt: string;
  updatedAt: string;
}

interface IAuth {
  token: string;
  refreshToken: string;
}

const fetcher = AxiosFactory.create({
  baseURL: config.api.url,
  headers: {
    "x-user-agent": "0.0.1/web_v",
  },
});

export const auth = {
  get isLoggedIn(): boolean {
    const token = this.token;
    const clientId = this.clientId;
    if (token && clientId) return true;
    else return false;
  },

  get token(): string {
    return this.auth.token;
  },

  get clientId(): string {
    return this.user.clientId;
  },

  setToken(token: string): void {
    window.localStorage.setItem(localStorageKeys.JWT, token);
  },

  setClientId(clientId: string): void {
    window.localStorage.setItem(localStorageKeys.clientId, clientId);
  },

  setUser(user: IUser): void {
    const formattedUser = JSON.stringify(user);
    window.localStorage.setItem(localStorageKeys.user, formattedUser);
  },

  setAuth(auth: IAuth): void {
    const formattedAuth = JSON.stringify(auth);
    window.localStorage.setItem(localStorageKeys.auth, formattedAuth);
  },

  get auth(): IAuth {
    const auth = window.localStorage.getItem(localStorageKeys.auth) || "{}";

    return JSON.parse(auth);
  },

  get user(): IUser {
    const user = window.localStorage.getItem(localStorageKeys.user) || "{}";
    return JSON.parse(user);
  },

  async logIn(emailOrPhone: string, password: string): Promise<void> {
    const res1 = await fetcher.post("auth/v1/users/login/step1", {
      emailOrPhone: emailOrPhone.toLowerCase(),
    });
    const res2 = await fetcher.post("auth/v1/users/login/step2", {
      password,
      loginToken: res1.data.loginToken,
      deviceId: window.navigator.userAgent,
    });
    this.setUser(res2.data.user);
    this.setAuth(res2.data.auth);
  },

  logOut(): void {
    window.localStorage.removeItem(localStorageKeys.auth);
    window.localStorage.removeItem(localStorageKeys.user);
  },
};
