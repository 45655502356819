import { AxiosResponse } from "axios";
import { UsersRepository } from "./repositories/UsersRepository";
import { auth } from "..";
import { localStorageKeys } from "..";
import { email } from "vee-validate/dist/rules";
export interface LogInRes {
  auth: {
    token: string;
    refreshToken: string;
  };
  user: {
    id: string;
    clientId: string;
    status: string;
    statusUpdatedAt: string;
    email: string;
    phoneNumber: string;
    promotionalNotificationsAccepted: boolean;
    notificationsAccepted: boolean;
    dataCollectionAccepted: boolean;
    createdAt: string;
    updatedAt: string;
  };
}

export interface LogInStep1Res {
  loginToken: string;
}

export const LogInUseCases = {
  _currentUser: undefined,
  getCurrentUser(): LogInRes | undefined {
    return this._currentUser;
  },
  async LogIn(emailOrPhone: string, password: string): Promise<void> {
    try {
      await auth.logIn(emailOrPhone, password);
    } catch (error) {
      console.error(error);
      throw error;
    }
  },
};
