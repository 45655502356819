import { RegisterUseCases } from "@/core";
import { auth } from "@/core/";
import { LogInUseCases } from "@/core/LogIn/LogInUseCases";
import { User } from "@/core/types/User.d";
import { VuexModule } from "../store";

interface RegisterState {
  currentUser?: User;
  email: string;
  phone: string;
  smsCode: string;
  password: string;
  experience: string;
  phoneIsValid: boolean;
  dataCollectionAccepted: boolean;
  notificationsAccepted: boolean;
  promotionalNotificationsAccepted: boolean;
  id: string;
  lastNumberSmsCodeSent: string;
  allowResendSmsCode: boolean;
}

interface RegisterGetters {
  phone: string;
}

let intervalId = 0;

const register: VuexModule<RegisterState, RegisterGetters> = {
  namespaced: true,
  state: () => ({
    currentUser: undefined,
    id: "",
    email: "",
    phone: "",
    smsCode: "",
    password: "",
    experience: "",
    phoneIsValid: false,
    dataCollectionAccepted: true,
    notificationsAccepted: true,
    promotionalNotificationsAccepted: true,
    lastNumberSmsCodeSent: "",
    allowResendSmsCode: true,
  }),
  mutations: {
    setEmail(state: RegisterState, val: string): void {
      state.email = val;
    },
    setPhone(state: RegisterState, val: string): void {
      state.phone = val;
    },
    setSMSCode(state: RegisterState, val: string): void {
      state.smsCode = val;
    },
    setPassword(state: RegisterState, val: string): void {
      state.password = val;
    },
    setExperience(state: RegisterState, val: string): void {
      state.experience = val;
    },
    setPhoneIsValid(state: RegisterState, val: boolean): void {
      state.phoneIsValid = val;
    },
    setCurrentUser(state: RegisterState, val: User): void {
      state.currentUser = val;
    },
    setDataCollection(state: RegisterState, val: boolean): void {
      state.dataCollectionAccepted = val;
    },
    setAllowResendSmsCode(state: RegisterState, val: boolean): void {
      state.allowResendSmsCode = val;
    },
    toggleAllowResendSmsCode(state: RegisterState): void {
      state.allowResendSmsCode = !state.allowResendSmsCode;
    },
  },
  actions: {
    setDataCollection({ commit }, val: boolean): void {
      commit("setDataCollection", val);
    },
    setEmail({ commit }, val: string): void {
      commit("setEmail", val);
    },
    setPhone({ commit }, val: string): void {
      commit("setPhone", val);
    },
    setSMSCode({ commit }, val: string): void {
      commit("setSMSCode", val);
    },
    setPassword({ commit }, val: string): void {
      commit("setPassword", val);
    },
    setExperience({ commit }, val: string): void {
      commit("setExperience", val);
    },
    async validatePhone({ commit, getters }): Promise<unknown> {
      commit("setPhoneIsValid", false);
      const { data } = await RegisterUseCases.validatePhone(getters.phone);
      if (data.result === "VALID") {
        commit("setPhoneIsValid", true);
      }
      return data;
    },
    async validateEmail(_, val: string): Promise<unknown> {
      const { data } = await RegisterUseCases.validateEmail(val);
      return data;
    },
    async sendCode({ getters, state, dispatch }): Promise<unknown> {
      if (state.phoneIsValid && state.allowResendSmsCode) {
        const res = await RegisterUseCases.sendCode(getters.phone);
        dispatch("startResendSmsCodeCountdown");
        return res;
      }
    },
    async validateCode({ commit, getters }, code: string): Promise<void> {
      try {
        const { data } = await RegisterUseCases.validateCode(
          getters.phone,
          code
        );
        if (data.codeValidated) {
          commit("setSMSCode", code);
        }
        return data;
      } catch (e) {
        console.error(e);
      }
    },
    async sendDownloadSMS({ state }): Promise<void> {
      try {
        if (state.email && state.password) {
          await LogInUseCases.LogIn(state.email, state.password);
        }
        await RegisterUseCases.sendDownloadSMS(auth.user.id);
      } catch (error) {
        console.error(error);
      }
    },
    async createUser({ state, commit, getters }): Promise<void> {
      const throwErr = (errName: string) => {
        throw new Error(errName);
      };
      if (!state.email) throwErr("email");
      if (!state.phone) throwErr("phone");
      if (!state.smsCode) throwErr("sms");
      if (!state.password) throwErr("password");

      const newUser = {
        email: state.email,
        password: state.password,
        phoneNumber: getters.phone,
        phoneValidationCode: state.smsCode,
        dataCollectionAccepted: state.dataCollectionAccepted,
        notificationsAccepted: state.notificationsAccepted,
        promotionalNotificationsAccepted:
          state.promotionalNotificationsAccepted,
      };
      const res = await RegisterUseCases.createUser(newUser);
      if (res.status !== 201) {
        throw new Error("USER_CREATION_ERROR");
      } else {
        await auth.logIn(state.email, state.password);
        commit("setCurrentUser", res.data);
      }
    },
    startResendSmsCodeCountdown({ commit }): void {
      const countdown = 30000;
      window.clearTimeout(intervalId);
      commit("setAllowResendSmsCode", false);
      const toggle = () => commit("toggleAllowResendSmsCode");
      intervalId = window.setTimeout(toggle, countdown);
    },
  },
  getters: {
    phone(state: RegisterState) {
      return "+34" + state.phone;
    },
  },
};

export { register };
