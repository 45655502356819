import { RestRepository } from "../../base/RestRepository";
import defaultConfig from "../../config";

const ValidationsRepository = new RestRepository({
  version: "v1",
  isPublic: true,
  path: "validations",
  baseURL: defaultConfig.api.url,
});

export { ValidationsRepository };
