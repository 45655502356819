


























































import { Component, Prop, Vue } from "vue-property-decorator";
import CloseIcon from "vue-material-design-icons/Close.vue";

@Component({
  components: {
    CloseIcon,
  },
})
export default class extends Vue {
  @Prop({
    type: String,
    default: "md",
    required: false,
  })
  readonly height!: "md" | "sm";
  @Prop({
    type: String,
    default: "md",
    required: false,
  })
  readonly width!: "md" | "lg"; // can't come up with a better name, sorry
  @Prop({
    type: Boolean,
    default: false,
    required: false,
  })
  readonly bgGray!: boolean;

  get showModal(): boolean {
    return this.$store.state.modal.show;
  }

  get hideCloseButton(): boolean {
    return this.$store.state.modal.hideCloseButton;
  }

  get modalComponent(): unknown {
    return this.$store.state.modal.component;
  }

  closeModal(): void {
    this.$store.dispatch("modal/hide");
  }

  get heightClasses(): Record<string, boolean> {
    return {
      "h-4/5": this.height === "md",
      "h-2/5": this.height === "sm",
    };
  }

  get widthClasses(): Record<string, boolean> {
    return {
      "w-4/5": this.width === "md",
      "nn-modal-lg": this.width === "lg",
    };
  }
}
