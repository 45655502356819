export const formatDate = (date: string): string => {
  const D = new Date(date);

  const months = [
    "Ene",
    "Feb",
    "Mar",
    "Abr",
    "May",
    "Jun",
    "Jul",
    "Ago",
    "Sep",
    "Oct",
    "Nov",
    "Dic",
  ];

  const day = D.getDate();
  const month = months[D.getMonth()];
  const year = D.getFullYear();
  return `${day} ${month}. ${year}`;
};
