import { IEstimationRes, OrdersUseCases } from "@/core/Orders/OrdersUseCases";
import { ICompany, ICashBalance, IOrderType, IOrderSide } from "@/core/types";
import { VuexModule } from "../store";

interface OrdersState {
  company?: ICompany;
  cashBalance?: ICashBalance;
  estimate?: IEstimationRes;
  prices?: IEstimationRes;
}
interface EstimateConfig {
  assetId: string;
  shares: number;
  price: number;
  orderSide: IOrderSide;
  orderType: IOrderType;
}

const buy: VuexModule<OrdersState, undefined> = {
  namespaced: true,
  state: () => ({
    company: undefined,
    prices: undefined,
    cashBalance: undefined,
    estimate: undefined,
  }),
  mutations: {
    SET_COMPANY(state: OrdersState, val: ICompany) {
      state.company = val;
    },
    SET_PRICES(state: OrdersState, val: IEstimationRes) {
      state.prices = val;
    },
    SET_CASHBALANCE(state: OrdersState, val: ICashBalance) {
      state.cashBalance = val;
    },
    SET_ESTIMATE(state: OrdersState, val: any) {
      state.estimate = val;
    },
  },
  actions: {
    async fetchEstimate({ commit }, config: EstimateConfig) {
      const data = await OrdersUseCases.fetchEstimate(config);
      commit("SET_ESTIMATE", data);
    },
    async fetchCompany({ commit }, id: string) {
      const data = await OrdersUseCases.fetchCompany(id);
      commit("SET_COMPANY", data);
    },
    async fetchPrices({ commit }, id: string) {
      const data = await OrdersUseCases.fetchEstimate({
        assetId: id,
        shares: 1,
        orderSide: "BUY",
        orderType: "MARKET",
      });
      commit("SET_PRICES", data);
    },
    async fetchCashBalance({ commit }) {
      const data = await OrdersUseCases.fetchCashBalance();
      commit("SET_CASHBALANCE", data);
    },
    async loadData({ dispatch }, id: string) {
      await Promise.all([
        dispatch("fetchCompany", id),
        dispatch("fetchPrices", id),
        dispatch("fetchCashBalance"),
      ]);
    },
    async sendBuyOrder(_, config: EstimateConfig) {
      const res = await OrdersUseCases.sendOrder(config);
      return res;
    },
  },
  getters: {},
};

export { buy };
