import numeral from "numeral";
numeral.register("locale", "nn", {
  delimiters: {
    thousands: ".",
    decimal: ",",
  },
  abbreviations: {
    thousand: "k",
    million: "M",
    billion: "mil M",
    trillion: "T",
  },
  ordinal: function (number) {
    return number === 1 ? "o" : "s";
  },
  currency: {
    symbol: "€",
  },
});
numeral.locale("nn");

interface IFormatNumberConfig {
  hideAbbr?: boolean;
}

export const formatNumber = (
  n: number,
  config?: IFormatNumberConfig
): string => {
  const MILLION = 1_000_000;

  // for millions and up use abbreviations
  if (config?.hideAbbr) {
    return numeral(n).format("0,0.00");
  } else if (n >= MILLION) {
    const format = "0,0.00a";
    return numeral(n).format(format);
  } else {
    return numeral(n).format("0,0.00");
  }
};
