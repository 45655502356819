import { RestRepository } from "../base/RestRepository";
import config from "../config";

interface IMarketStatusRes {
  nextClose: string;
  nextOpen: string;
  open: boolean;
}

const MarketsRepository = new RestRepository({
  version: "v1",
  isPublic: false,
  path: "markets",
  baseURL: config.api.url,
});

export async function getMarketStatus(): Promise<IMarketStatusRes> {
  const response = await MarketsRepository.get("/status");

  return response.data;
}
