






import { formatAmount } from "@/core";
import { IAmount } from "@/core/types";
import { formatNumber } from "@/core/utils/formatNumber";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BaseAmount extends Vue {
  @Prop(Object) readonly amount!: IAmount;
  @Prop(Boolean) readonly styled!: boolean;
  @Prop(Boolean) readonly showOperator!: boolean;
  @Prop(Boolean) readonly hideCurrency!: boolean;
  @Prop(Boolean) readonly hideTooltip?: boolean;
  @Prop({ type: Boolean, default: false }) readonly hideAbbr?: boolean;

  get tooltip(): string | null {
    if (this.hideTooltip) return null;
    if (this.amount.value > 1000000) {
      return formatNumber(this.amount.value, {
        hideAbbr: true,
      });
    } else return null;
  }

  get classes(): Record<string, boolean> {
    if (this.styled) {
      return {
        "text-green-500": this.amount.value > 0,
        "text-red-500": this.amount.value < 0,
      };
    } else {
      return {};
    }
  }

  get numberSymbol(): string {
    if (this.showOperator) {
      if (this.amount.value > 0) return "+";
      else return ""; // don't show - operator as it comes with it already
    } else {
      return "";
    }
  }

  get formattedNumber(): string {
    if (this.hideCurrency) {
      return formatNumber(this.amount.value, {
        hideAbbr: this.hideAbbr,
      });
    } else {
      return formatAmount(this.amount);
    }
  }
}
