import { RestRepository } from "../../base/RestRepository";
import defaultConfig from "../../config";

const ResetPasswordRepository = new RestRepository({
  version: "v1",
  isPublic: true,
  path: "users/reset-password",
  baseURL: defaultConfig.api.url,
});

export { ResetPasswordRepository };
