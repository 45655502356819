import { AxiosResponse } from "axios";
import { CreateUserBody } from "../types/User";
import { ValidationsRepository } from "./";
import {
  PrivUsersRepository,
  UsersRepository,
} from "./repositories/UserRepository";

export const RegisterUseCases = {
  async validatePhone(val: string): Promise<AxiosResponse> {
    const res = await ValidationsRepository.put("phone", {
      phoneNumber: val,
    });
    return res as AxiosResponse;
  },
  async validateEmail(val: string): Promise<AxiosResponse> {
    const res = await ValidationsRepository.put("email", {
      email: val,
    });
    return res as AxiosResponse;
  },
  async sendCode(chAddress: string): Promise<AxiosResponse> {
    const res = await ValidationsRepository.post("send-code", {
      channelAddress: chAddress,
    });

    return res as AxiosResponse;
  },
  async validateCode(chAddress: string, code: string): Promise<AxiosResponse> {
    const res = await ValidationsRepository.post("validate-code", {
      channelAddress: chAddress,
      code: code,
    });

    return res as AxiosResponse;
  },
  async createUser(userData: CreateUserBody): Promise<AxiosResponse> {
    const res = await UsersRepository.post("", userData);
    return res as AxiosResponse;
  },
  async sendDownloadSMS(userId: string): Promise<AxiosResponse> {
    const res = await PrivUsersRepository.post(userId + "/send-app-sms");
    return res as AxiosResponse;
  },
};
