






















import { Component, Prop, Vue, Watch } from "vue-property-decorator";

interface RadioOptionItem {
  label: string;
  value: string;
}

@Component
export default class BaseRadioGroup extends Vue {
  @Prop(Array)
  readonly options!: RadioOptionItem[];

  value = this.$attrs.value || "";

  @Watch("value")
  onValueChange(): void {
    this.$emit("change", this.value);
    this.$emit("input", this.value);
  }
}
