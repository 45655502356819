import { auth } from "@/core/";
import { LogInUseCases } from "@/core/LogIn/LogInUseCases";
import { LogInRes } from "@/core/LogIn/LogInUseCases";
import { MyProfileUseCases } from "@/core/MyProfile/MyProfileUseCases";
import { VuexModule } from "../store";

interface UserState {
  user?: LogInRes;
  client?: unknown;
}

interface UserGetters {
  isLoggedIn: boolean;
  clientId: string;
}

interface LogInParams {
  emailOrPhone: string;
  password: string;
}

const user: VuexModule<UserState, UserGetters> = {
  namespaced: true,
  state: () => ({
    user: undefined,
    client: undefined,
  }),
  mutations: {
    SET_USER(state, user: LogInRes): void {
      state.user = user;
    },
    SET_CLIENT(state, client): void {
      state.client = client;
    },
  },
  actions: {
    setUser({ commit }, user: LogInRes): void {
      commit("SET_USER", user);
    },
    async login(
      { commit },
      { emailOrPhone, password }: LogInParams
    ): Promise<void> {
      await LogInUseCases.LogIn(emailOrPhone, password);
      const { data } = (await MyProfileUseCases.fetchClient(
        auth.clientId
      )) as any;

      commit("SET_CLIENT", data);
      commit("SET_USER", auth.user);
    },
  },
  getters: {
    isLoggedIn(): boolean {
      return auth.isLoggedIn;
    },
  },
};

export { user };
