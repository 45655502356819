






import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BaseText extends Vue {
  @Prop(Boolean) readonly h1!: boolean;
  @Prop(Boolean) readonly h2!: boolean;
  @Prop(Boolean) readonly link!: boolean;

  get classes(): string {
    if (this.h1) return "font-medium text-2xl md:text-3xl text-gray-900";
    if (this.h2) return "font-medium text-xl md:text-2xl text-gray-900";
    if (this.link) return "text-blue-500 inline hover:cursor-pointer";
    else return "text-gray-500";
  }

  get role(): string {
    if (this.h1) return "h1";
    if (this.h2) return "h2";
    if (this.link) return "a";
    else return "p";
  }
}
