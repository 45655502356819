import { auth, currencyKey } from "..";
import { ICompany } from "../types/Company";
import { ICashBalance } from "../types/CashBalance";
import {
  ClientsRepositoryV2,
  ClientsRepositoryV1,
} from "./repositories/ClientsRepository";
import { AssetsRepository } from "./repositories/AssetsRepository";
import { OrdersRepository } from "./repositories/OrdersRepository";
import { IAmount, IOrderSide, IOrderType, IPosition } from "../types";

export interface IEstimationArgs {
  price?: number;
  shares: number;
  assetId: string;
  orderSide: IOrderSide;
  orderType: IOrderType;
}

export interface IEstimationRes {
  orderSide: IOrderSide;
  orderType: IOrderType;
  shares: number;
  assetPrice: {
    value: IAmount;
    mid: IAmount;
    ask: IAmount;
    bid: IAmount;
  };
  costInAssetCurrency: IAmount;
  costInClientsCurrencyWithoutCommission: IAmount;
  costInClientsCurrencyWithCommission: IAmount;
  forexCost: IAmount;
  settlementCost: IAmount;
  totalCost: IAmount;
  forexInfo: {
    rate: {
      value: number;
      currency: currencyKey;
      counterCurrency: currencyKey;
    };
    commission: number;
    rateWithCommission: {
      value: number;
      currency: currencyKey;
      counterCurrency: currencyKey;
    };
  };
  cashback: IAmount;
}

export interface ISendOrderConfig {
  assetId: string;
  shares: number;
  price?: number;
  orderSide: IOrderSide;
  orderType: IOrderType;
}

export interface ISendOrderRes {
  assetSnapshot: {
    companyName: string;
    currency: currencyKey;
    hot: true;
    id: string;
    isin: string;
    price: IAmount;
    symbol: string;
  };
  createdAt: string;
  customPrice: IAmount;
  estimation: {
    forex: {
      commission: number;
      rate: {
        counterCurrency: string;
        currency: string;
        value: number;
      };
      rateWithCommission: {
        counterCurrency: string;
        currency: string;
        value: number;
      };
    };
    forexCost: IAmount;
    settlementCost: IAmount;
    totalAmountInAssetCurrency: IAmount;
    totalAmountInClientCurrency: IAmount;
    totalCostInClientCurrency: IAmount;
  };
  executionSummary: {
    averageForexRateWithCommission: {
      counterCurrency: "EUR";
      currency: "EUR";
      value: number;
    };
    averageForexRateWithoutCommission: {
      counterCurrency: "EUR";
      currency: "EUR";
      value: number;
    };
    averagePricePerShare: IAmount;
    settlementCost: IAmount;
    sharesExecuted: number;
    sharesNotExecuted: number;
    totalAmountInAssetCurrency: IAmount;
    totalAmountInClientCurrencyWithCommission: IAmount;
    totalAmountInClientCurrencyWithoutCommission: IAmount;
    totalCommission: IAmount;
    totalForexCost: IAmount;
  };
  expiresAt: string;
  hasNote: true;
  id: string;
  shares: number;
  side: string;
  status: string;
  type: string;
  updatedAt: string;
}

export const OrdersUseCases = {
  sendMarketSellOrder: async (config: {
    assetId: string;
    shares: number;
  }): Promise<ISendOrderRes> => {
    const { assetId, shares } = config;
    const res = await OrdersRepository.post("", {
      assetId,
      shares,
      orderSide: "SELL",
      orderType: "MARKET",
    });

    return res.data;
  },
  fetchShareTotal: async (assetId: string): Promise<number> => {
    const { clientId } = auth;
    const { data } = await ClientsRepositoryV2.get("/portfolio", {
      params: {
        clientId,
      },
    });
    const position = data.positions.find(
      (p: IPosition) => p.asset.assetId === assetId
    );
    const { shares } = position;
    return shares;
  },
  fetchPosition: async (assetId: string): Promise<IPosition[]> => {
    const res = await OrdersRepository.get("/position", {
      params: {
        assetId,
      },
    });

    return res.data;
  },
  fetchCompany: async (id: string): Promise<ICompany> => {
    const res = await AssetsRepository.get(id);
    return res.data;
  },
  fetchEstimate: async (
    estimation: IEstimationArgs
  ): Promise<IEstimationRes> => {
    const res = await OrdersRepository.get("/estimate", {
      params: {
        assetId: estimation.assetId,
        shares: estimation.shares,
        price: estimation.price,
        orderSide: estimation.orderSide,
        orderType: estimation.orderType,
      },
    });
    return res.data;
  },
  fetchCashBalance: async (): Promise<ICashBalance> => {
    const clientId = auth.clientId;
    const { data } = await ClientsRepositoryV1.get(`/${clientId}/cash-balance`);

    return data;
  },
  sendOrder: async (config: ISendOrderConfig): Promise<unknown> => {
    const { data } = await OrdersRepository.post(``, config);
    return data;
  },
};
