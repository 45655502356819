import { CollectionsRepository } from "./";

export const FavUseCases = {
  async setFav(assetId: string): Promise<unknown> {
    const res = await CollectionsRepository.put(
      "/favorites",
      {},
      {
        params: {
          assetId,
        },
      }
    );
    return res;
  },
  async unsetFav(assetId: string): Promise<unknown> {
    const res = await CollectionsRepository.delete("/favorites", {
      params: {
        assetId,
      },
    });
    return res;
  },
};
