// TODO: get a better regex to validate emails 🤡
export function validateEmail(email: string): boolean {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

export function stringIsAllNumbers(string: string): boolean {
  const re = /^\d+$/;
  return re.test(string);
}

export function formatEmailOrPhone(string: string): string {
  if (stringIsAllNumbers(string)) {
    return `+34${string}`;
  } else {
    return string;
  }
}

export * from "./getCurrencySymbol";
export * from "./formatAmount";
export * from "./toggleHelpScoutBeacon";
export * from "./formatDate";
export * from "./getMarketStatus";
export * from "./toggleScroll";
export * from "./formatVolume";
