import { RestRepository } from "../../base/RestRepository";
import defaultConfig from "../../config";

const ClientsRepository = new RestRepository({
  version: "v2",
  isPublic: false,
  path: "clients",
  baseURL: defaultConfig.api.url,
});

export { ClientsRepository };
