import { RestRepository } from "../../base/RestRepository";
import defaultConfig from "../../config";

const CollectionsRepository = new RestRepository({
  version: "v1",
  isPublic: false,
  path: "collections",
  baseURL: defaultConfig.api.url,
});

export { CollectionsRepository };
