import { RestRepository } from "../base/RestRepository";
import defaultConfig from "../config";

const AssetsRepository = new RestRepository({
  version: "v1",
  isPublic: true,
  path: "assets",
  baseURL: defaultConfig.api.url,
});
const AssetsRepositoryV2 = new RestRepository({
  version: "v2",
  isPublic: true,
  path: "assets",
  baseURL: defaultConfig.api.url,
});

export { AssetsRepository, AssetsRepositoryV2 };
