




























import { Component, Prop, Vue } from "vue-property-decorator";
import MinusIcon from "vue-material-design-icons/Minus.vue";
import PlusIcon from "vue-material-design-icons/Plus.vue";

@Component({
  components: {
    MinusIcon,
    PlusIcon,
  },
})
export default class BaseButton extends Vue {
  @Prop(String)
  readonly id!: string;
  @Prop(String)
  readonly name!: string;
  @Prop(Number)
  value!: number;
  @Prop(Boolean)
  readonly disabled!: boolean;
  @Prop(Boolean)
  readonly loading!: boolean;
  @Prop()
  readonly max?: number;
  @Prop()
  readonly min?: number;
  @Prop({ default: 1 })
  readonly step!: number;

  innerValue = this.value;

  inputUp(): void {
    const newVal = this.innerValue + this.step;
    if (this.max && newVal <= this.max) {
      this.innerValue = newVal;
      this.$emit("input", newVal);
    }
  }
  inputDown(): void {
    const newVal = this.innerValue - this.step;
    if (this.min && newVal >= this.min) {
      this.innerValue = newVal;
      this.$emit("input", this.innerValue);
    }
  }
}
