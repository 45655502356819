/* eslint-disable @typescript-eslint/no-unused-vars */
import Vue from "vue";
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from "vue-router";
import { auth } from "@/core/";
Vue.use(VueRouter);

const logout = () => {
  auth.logOut();
  const loginUrl =
    window.location.protocol + "//" + window.location.host + "/inicio-sesion";

  window.location.replace(loginUrl);
};

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "dashboard",
    redirect: "/cartera",
  },
  {
    path: "/registro",
    name: "register",
    redirect: "/crear-cuenta/paso-email",
    meta: {
      public: true,
    },
  },
  {
    path: "/registro/paso-continua-app",
    name: "continue-to-app",
    component: () => import("../views/ContinueFromApp.vue"),
    meta: {
      public: false,
      preventExit: false,
      layout: "empty",
    },
  },
  {
    path: "*",
    name: "Oops",
    redirect: "/",
    meta: {
      public: true,
    },
  },
  {
    path: "/error",
    name: "Error",
    component: () => import("../views/Error.vue"),
    meta: {
      layout: "empty",
      public: true,
    },
  },
  {
    path: "/inicio-sesion",
    name: "login",
    component: () => import("../views/LogIn.vue"),
    meta: {
      public: true,
      layout: "empty",
    },
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext): void => {
      if (auth.isLoggedIn) {
        next("/");
      } else {
        next();
      }
    },
  },
  {
    path: "/recuperar-contrasena",
    name: "forgot-password",
    component: () => import("../views/ForgotPassword.vue"),
    redirect: "/recuperar-contrasena/paso-email",
    meta: {
      public: true,
    },
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext): void => {
      // This is to prevent the user from going directly to any step that's not the first one
      if (process.env.NODE_ENV === "development") {
        next();
      } else if (to.name !== "forgot-password-email") {
        next({ name: "forgot-password-email" });
      } else {
        next();
      }
    },
    children: [
      {
        path: "paso-email",
        name: "forgot-password-email",
        component: () => import("../components/forgot-password/EmailStep.vue"),
        meta: {
          layout: "forgotPassword",
        },
      },
      {
        path: "paso-sms",
        name: "forgot-password-sms",
        component: () => import("../components/forgot-password/SmsStep.vue"),
        meta: {
          layout: "forgotPassword",
          preventExit: true,
        },
      },
      {
        path: "paso-contrasena",
        name: "forgot-password-password",
        component: () =>
          import("../components/forgot-password/PasswordStep.vue"),
        meta: {
          layout: "forgotPassword",
          preventExit: true,
        },
      },
      {
        path: "paso-confirmacion",
        name: "forgot-password-confirm",
        component: () =>
          import("../components/forgot-password/ConfirmStep.vue"),
        meta: {
          layout: "empty",
        },
      },
    ],
  },
  {
    path: "/cartera",
    name: "portfolio",
    component: () => import("../views/Portfolio.vue"),
    meta: {
      layout: "app",
    },
  },
  {
    path: "/logout",
    name: "logout",
    beforeEnter: (): void => {
      logout();
    },
  },

  // Sign up AB TEST routes
  {
    path: "/crear-cuenta",
    name: "sign-up-b",
    component: () => import("../views/BSignUp.vue"),
    redirect: "/crear-cuenta/paso-email",
    meta: {
      public: true,
    },
    beforeEnter: (to: Route, from: Route, next: NavigationGuardNext): void => {
      // This is to prevent the user from going directly to any step that's not the first one
      if (process.env.NODE_ENV === "development") {
        next();
      } else if (to.name !== "b-email-step") {
        next({ name: "b-email-step" });
      } else {
        next();
      }
    },
    children: [
      {
        path: "paso-email",
        name: "b-email-step",
        component: () => import("../components/register/BSignUpEmailStep.vue"),
        meta: {
          preventExit: false,
          layout: "bSignUp",
        },
      },
      {
        path: "paso-numero-telefono",
        name: "b-phone-step",
        component: () => import("../components/register/BSignUpPhoneStep.vue"),
        meta: {
          preventExit: true,
          layout: "bSignUp",
        },
      },
      {
        path: "paso-sms",
        name: "b-sms-step",
        component: () =>
          import("../components/register/BSignUpSMSCodeStep.vue"),
        meta: {
          preventExit: true,
          layout: "bSignUp",
        },
      },
      {
        path: "paso-contrasena",
        name: "b-password-step",
        component: () =>
          import("../components/register/BSignUpPasswordStep.vue"),
        meta: {
          preventExit: true,
          layout: "bSignUp",
        },
      },
    ],
  },
  {
    path: "/crear-cuenta/continuar-en-app",
    name: "b-continue-to-app",
    component: () =>
      import("../components/register/BSignUpContinueFromApp.vue"),
    meta: {
      public: false,
      preventExit: false,
      layout: "empty",
    },
  },
  {
    path: "/producto/:id",
    name: "product",
    component: () => import("../views/Product.vue"),
    meta: {
      layout: "app",
    },
  },
  {
    path: "/comprar/:id",
    name: "buy",
    component: () => import("../views/Buy.vue"),
    meta: {
      layout: "orders",
      title: "Order_Buy_Title",
    },
  },
  {
    path: "/vender/:id",
    name: "sell",
    component: () => import("../views/Sell.vue"),
    meta: {
      layout: "orders",
      title: "Order_Sell_Title",
    },
  },
  {
    path: "/cookies",
    name: "cookies",
    component: () => import("../views/Cookies.vue"),
    meta: {
      public: true,
      layout: "empty",
    },
  },
];

// Scroll to top on route changes.
// https://router.vuejs.org/api/#scrollbehavior
const scrollBehavior = () => {
  return { x: 0, y: 0 };
};

// According to MDN this code is fine but typescript's Event type won't allow passing a string to e.returnValue so I have to type it as any for this function.
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function preventReload(e: any) {
  e.preventDefault();
  const confirmationMessage =
    "¿Quieres volver a cargar esta página?" +
    "Es posible que los cambios no se guarden.";
  return (e.returnValue = confirmationMessage);
}

const afterEach = (to: Route, from: Route): void => {
  if (to.meta?.preventExit) {
    window.addEventListener("beforeunload", preventReload);
  } else {
    window.removeEventListener("beforeunload", preventReload);
    window.removeEventListener("onunload", preventReload);
  }
};

const calculateNextRoute = (to: Route, isLoggedIn: boolean): string | false => {
  const routeIsPublic = to.matched.some((r) => r.meta?.public);
  if (isLoggedIn && !routeIsPublic) return to.path;
  else if (!isLoggedIn && !routeIsPublic) return "/inicio-sesion";
  else if (!isLoggedIn && routeIsPublic) return to.path;
  else if (isLoggedIn && routeIsPublic) return "/cartera";
  else return "/inicio-sesion";
};

const openRoutes = ["/cookies"];

const beforeEach = (
  to: Route,
  from: Route,
  next: NavigationGuardNext,
  isLoggedIn: boolean
): void => {
  if (openRoutes.includes(to.path)) {
    next();
  } else {
    const nextRoute = calculateNextRoute(to, isLoggedIn);
    if (to.path === nextRoute) next();
    else next(nextRoute);
  }
};

// TODO: Type createRouter params properly.
type CreateRouterParams = any;

const createRouterInstance = ({
  mode = "history",
  base = process.env.BASE_URL,
  routes,
  scrollBehavior,
  afterEach,
  beforeEach,
  AuthService = auth,
}: CreateRouterParams): VueRouter => {
  const newRouter = new VueRouter({
    mode,
    base,
    routes,
    scrollBehavior,
  });
  newRouter.afterEach(afterEach);
  newRouter.beforeEach((to, from, next) =>
    beforeEach(to, from, next, AuthService.isLoggedIn)
  );

  return newRouter;
};

const router = createRouterInstance({
  routes,
  afterEach,
  beforeEach,
  scrollBehavior,
});

export {
  calculateNextRoute,
  beforeEach,
  afterEach,
  routes,
  router,
  createRouterInstance,
};
