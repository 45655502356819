import { auth, MarketStatus } from "@/core/";
import {
  PortfolioRes,
  PortfolioUseCases,
} from "@/core/Portfolio/PortfolioUseCases";
import { IPortfolio } from "@/core/types";
import { VuexModule } from "../store";

interface Amount {
  currency: string;
  value: number;
}

interface PortfolioPositionAsset {
  assetId: string;
  companyName: string;
  currentInvestedAmount: Amount;
  profit: {
    amount: Amount;
    percentage: number;
  };
  shares: number;
  synbol: string;
}

interface PortfolioState {
  polling: boolean;
  cashBalance: Amount;
  positions: PortfolioPositionAsset[] | [];
  totalCurrentInvestedAmount: Amount;
  totalAmount: Amount;
  profit: {
    amount: Amount;
    percentage: number;
  };
  favorites: [];
}

interface PortfolioGetters {
  clientId: string;
}

const portfolio: VuexModule<PortfolioState, PortfolioGetters> = {
  namespaced: true,
  state: (): PortfolioState => ({
    polling: false,
    cashBalance: {
      currency: "EUR",
      value: 0,
    },
    positions: [],
    totalCurrentInvestedAmount: {
      currency: "EUR",
      value: 0,
    },
    totalAmount: {
      currency: "EUR",
      value: 0,
    },
    profit: {
      amount: {
        currency: "EUR",
        value: 0,
      },
      percentage: 0,
    },
    favorites: [],
  }),
  mutations: {
    SET_CASHBALANCE(state, val) {
      state.cashBalance = val;
    },
    SET_TOTALAMOUNT(state, val) {
      state.totalAmount = val;
    },
    SET_POSITIONS(state, val) {
      state.positions = val;
    },
    SET_TOTALCURRENTINVESTEDAMOUNT(state, val) {
      state.totalCurrentInvestedAmount = val;
    },
    SET_PROFIT(state, val) {
      state.profit = val;
    },
    SET_FAVORITES(state, val) {
      state.favorites = val;
    },
    SET_POLLING(state, val) {
      state.polling = val;
    },
  },
  actions: {
    startPortfolio({ dispatch, state }) {
      dispatch("fetchPortfolio");
      const fetch = () => {
        if (state.polling && MarketStatus.open) {
          dispatch("fetchPortfolio");
        }
      };
      setInterval(fetch, 5000);
    },
    async fetchPortfolio({ getters, dispatch }) {
      const portfolio = await PortfolioUseCases.getPortfolio(
        getters["clientId"]
      );
      dispatch("setPortfolio", portfolio);
    },
    async fetchFavorites({ getters, commit }) {
      const favorites = await PortfolioUseCases.getFavs(getters["clientId"]);
      commit("SET_FAVORITES", favorites);
    },
    setPortfolio({ commit }, portfolio: IPortfolio) {
      commit("SET_CASHBALANCE", portfolio.cashBalance);
      commit("SET_POSITIONS", portfolio.positions);
      commit("SET_TOTALAMOUNT", portfolio.totalAmount);
      commit(
        "SET_TOTALCURRENTINVESTEDAMOUNT",
        portfolio.totalCurrentInvestedAmount
      );
      commit("SET_PROFIT", portfolio.profit);
    },
  },
  getters: {
    clientId(): string {
      return auth.clientId;
    },
    portfolio(state): PortfolioRes {
      return state;
    },
    status(state): string {
      if (state.positions.length === 0 && state.cashBalance.value === 0) {
        return "NO_CASH_NO_SHARES";
      } else if (state.positions.length === 0) {
        return "NO_SHARES";
      } else {
        return "OK";
      }
    },
  },
};

export { portfolio };
