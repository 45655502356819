import { IAssetSearchResult } from "../types";
import { AssetsRepository, AssetsRepositoryV2 } from "./SearchRepositories";

export const SearchUseCases = {
  async fetchPopularAssets(): Promise<IAssetSearchResult[]> {
    const res = await AssetsRepository.get("/most-favorites", {
      params: {
        hot: true,
      },
    });
    return res.data.items as IAssetSearchResult[];
  },
  async searchAssets(
    q: string,
    offset = 0
  ): Promise<{ items: IAssetSearchResult[]; totalItems: number }> {
    const res = await AssetsRepositoryV2.get("", {
      params: {
        text: q,
        max: 20,
        offset,
      },
    });
    return res.data;
  },
};
