import { auth, currencyKey, formatDate } from "..";
import { IOrder, IPosition, IProduct } from "../types";
import { formatTime } from "../utils/formatTime";
import { AssetsRepository } from "./repositories/AssetsRepository";
import { OrdersRepository } from "./repositories/OrdersRepository";
import { PositionRepository } from "./repositories/PositionRepository";
import { PricesRepository } from "./repositories/PricesRepository";

export type ITimeIntervals = "1d" | "1w" | "1m" | "3m" | "1y" | "5y";
export interface IPrice {
  value: number;
  time: string;
  open: number;
  close: number;
  volume: number;
  high: number;
  low: number;
  startTime: string;
  endTime: string;
  priceChange: number;
  percentChange: number;
}

export interface ICurrentPrice {
  ask: number;
  assetId: string;
  bid: number;
  change: number;
  close: number;
  currency: string;
  high: number;
  low: number;
  mid: number;
  open: number;
  percentChange: number;
  percentChangeFromPreviousClose: number;
  price: number;
  tradingHalted: boolean;
  volume: number;
}

export interface IFormattedPrice extends IPrice {
  priceChange: number;
  percentChange: number;
  timestamp: number;
}
export interface IPricesRes {
  items: IFormattedPrice[];
  currency: currencyKey;
}

export const ProductUseCases = {
  getProduct: async (id: string): Promise<IProduct> => {
    const res = await AssetsRepository.get(`/${id}`, {
      params: {
        clientId: auth.clientId,
      },
    });
    const marketCapitalization = res.data.fundamentals.marketCapitalization;
    if (marketCapitalization) {
      // Xignite returns market capitalization in millions which breaks formatting
      const formattedMarketCap = marketCapitalization.toFixed(2) * 1000000;
      res.data.fundamentals.formattedMarketCap = formattedMarketCap;
    }
    return res.data;
  },
  getPrices: async (
    id: string,
    interval: ITimeIntervals
  ): Promise<IPricesRes> => {
    const res = await PricesRepository.get(`/`, {
      params: {
        assetId: id,
        interval,
      },
    });

    function timeToLocal(originalTime: string) {
      const timestamp = new Date(originalTime).getTime() / 1000;
      const d = new Date(timestamp * 1000);
      return (
        Date.UTC(
          d.getFullYear(),
          d.getMonth(),
          d.getDate(),
          d.getHours(),
          d.getMinutes(),
          d.getSeconds(),
          d.getMilliseconds()
        ) / 1000
      );
    }

    const items = res.data[0].items.map((item: IPrice) => ({
      ...item,
      timestamp: timeToLocal(item.time), // necessary format for lightweight chart
      formattedDate: formatDate(item.time),
      formattedTime: formatTime(item.time, {
        showSeconds: false,
        showAMPM: false,
        type: "24h",
      }),
    }));

    const item = {
      ...res.data[0],
      items,
    };
    return item;
  },
  getCurrentPrice: async (id: string): Promise<ICurrentPrice> => {
    const { data } = await PricesRepository.get("/current", {
      params: {
        assetId: id,
      },
    });

    return data[0];
  },
  getMarketData: async (id: string): Promise<any> => {
    const res = await PricesRepository.get("/current", {
      params: {
        assetId: id,
      },
    });

    return res.data[0];
  },
  getPosition: async (assetId: string): Promise<IPosition> => {
    const res = await PositionRepository.get("", {
      params: {
        assetId,
        clientId: auth.clientId,
      },
    });

    return res.data.position;
  },
  getOKOrders: async (assetId: string): Promise<IOrder[]> => {
    const res = await OrdersRepository.get("/position", {
      params: {
        "asset-id": assetId,
      },
    });

    return res.data;
  },
};
