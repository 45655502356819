import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faChevronDown,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faEuroSign,
  faLock,
  faMinus,
  faPlus,
  faArrowLeft,
  faArrowRight,
  faCoins,
} from "@fortawesome/free-solid-svg-icons";
import {
  faQuestionCircle,
  faGrinWink,
  faCopy,
  faBookmark,
  faCheckCircle,
} from "@fortawesome/free-regular-svg-icons";
import {
  faGooglePlay,
  faAppStore,
  faApple,
} from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import Vue from "vue";

const icons = [
  faQuestionCircle,
  faChevronDown,
  faGrinWink,
  faEuroSign,
  faLock,
  faMinus,
  faPlus,
  faGooglePlay,
  faAppStore,
  faApple,
  faArrowLeft,
  faArrowRight,
  faCoins,
  faCopy,
  faCheckCircle,
  faChevronRight,
  faChevronLeft,
  faChevronUp,
  faBookmark,
];

icons.forEach((icon) => {
  library.add(icon);
});

Vue.component("fa-icon", FontAwesomeIcon);

export { icons };
