import { auth } from "@/core/";
import { PortfolioUseCases } from "@/core/Portfolio/PortfolioUseCases";
import { VuexModule } from "../store";

interface IBANState {
  iban: string;
}

interface IBANGetters {
  userHasIBAN: boolean;
}

const iban: VuexModule<IBANState, IBANGetters> = {
  namespaced: true,
  state: () => ({
    iban: "",
  }),
  mutations: {
    SET_IBAN(state, val) {
      state.iban = val;
    },
  },
  actions: {
    async setIBAN(_, iban: string) {
      await PortfolioUseCases.setIBAN(auth.clientId, "ES" + iban);
    },
    async fetchIBAN({ commit }) {
      commit("SET_IBAN", "");
      try {
        const res: any = await PortfolioUseCases.getIBAN(auth.clientId);
        commit("SET_IBAN", res.iban);
      } catch (error) {
        console.error(error);
      }
    },
  },
  getters: {
    userHasIBAN(state): boolean {
      return !!state.iban;
    },
  },
};

export { iban };
