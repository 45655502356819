export interface APIConfig {
  api: {
    url: string;
  };
  firebase: {
    apiKey: string;
    authDomain: string;
    databaseURL: string;
    projectId: string;
    storageBucket: string;
    messagingSenderId: string;
    appId: string;
    measurementId: string;
  };
}

const development: APIConfig = {
  api: {
    url: "http://localhost:4000",
  },
  firebase: {
    apiKey: "AIzaSyBZbwr74E_2lhNYycQ4aItxBG43oBkpzM4",
    authDomain: "markets-5a967.firebaseapp.com",
    databaseURL: "https://markets-5a967.firebaseio.com",
    projectId: "markets-5a967",
    storageBucket: "markets-5a967.appspot.com",
    messagingSenderId: "155930357030",
    appId: "1:155930357030:web:609554bbc8ad5f292ea5e7",
    measurementId: "G-F5TTH8VFED",
  },
};

const staging: APIConfig = {
  api: {
    url: "https://api-stg.ninetynine.com",
  },
  firebase: {
    apiKey: "AIzaSyBZbwr74E_2lhNYycQ4aItxBG43oBkpzM4",
    authDomain: "markets-5a967.firebaseapp.com",
    databaseURL: "https://markets-5a967.firebaseio.com",
    projectId: "markets-5a967",
    storageBucket: "markets-5a967.appspot.com",
    messagingSenderId: "155930357030",
    appId: "1:155930357030:web:609554bbc8ad5f292ea5e7",
    measurementId: "G-F5TTH8VFED",
  },
};

const production: APIConfig = {
  api: {
    url: "https://api-prod.ninetynine.com",
  },
  firebase: {
    apiKey: "AIzaSyBZbwr74E_2lhNYycQ4aItxBG43oBkpzM4",
    authDomain: "markets-5a967.firebaseapp.com",
    databaseURL: "https://markets-5a967.firebaseio.com",
    projectId: "markets-5a967",
    storageBucket: "markets-5a967.appspot.com",
    messagingSenderId: "155930357030",
    appId: "1:155930357030:web:609554bbc8ad5f292ea5e7",
    measurementId: "G-F5TTH8VFED",
  },
};

const test: APIConfig = {
  api: {
    url: "https://localhost:3000",
  },
  firebase: {
    apiKey: "AIzaSyBZbwr74E_2lhNYycQ4aItxBG43oBkpzM4",
    authDomain: "markets-5a967.firebaseapp.com",
    databaseURL: "https://markets-5a967.firebaseio.com",
    projectId: "markets-5a967",
    storageBucket: "markets-5a967.appspot.com",
    messagingSenderId: "155930357030",
    appId: "1:155930357030:web:609554bbc8ad5f292ea5e7",
    measurementId: "G-F5TTH8VFED",
  },
};

export default {
  production,
  development,
  staging,
  test,
} as Record<string, APIConfig>;
