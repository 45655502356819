export interface IFormatTimeConfig {
  showSeconds?: boolean;
  showAMPM?: boolean;
  type: "24h" | "12h";
}

export const formatTime = (
  time: string | number | Date,
  config: IFormatTimeConfig
): string => {
  const D = new Date(time);
  let hours = D.getHours();
  let minutes: number | string = D.getMinutes();
  let seconds: number | string = D.getSeconds();
  const ampm = hours >= 12 ? "pm" : "am";
  if (config.type === "12h") {
    hours = hours % 12;
  }
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  const timeStr = `${hours}:${minutes}${
    config.showSeconds ? `:${seconds}` : ""
  }${config.showAMPM ? ` ${ampm}` : ""}`;

  return timeStr;
};
