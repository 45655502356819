import Vue from "vue";
import App from "./App.vue";
import { router } from "./router";
import store from "./store";
import { GlobalComponents } from "./components/global";
import VueI18n from "vue-i18n";
import { messages, defaultLocale } from "./i18n/index";
import "./plugins";
import "./registerServiceWorker";
import "./assets/styles/main.scss";
import tracking from "@/core/plugins/TrackingPlugin";
import { auth, MarketStatus } from "./core/";
import { MyProfileUseCases } from "./core/MyProfile/MyProfileUseCases";
import VueObserveVisibility from "vue-observe-visibility";
import { initZendeskWidget } from "./core/plugins";

Vue.use(VueI18n);
Vue.use(tracking);
Vue.use(VueObserveVisibility);

if (window.location.host.includes("localhost")) {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { onMocksChange } = require("./mocks/ui");
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { worker } = require("./mocks/browser");

  onMocksChange((enabled: boolean) => {
    if (enabled) {
      worker.start();
    } else {
      worker.stop();
    }
  });
}

const i18n = new VueI18n({
  messages,
  locale: defaultLocale,
  fallbackLocale: defaultLocale,
});

// Register all global components
Object.keys(GlobalComponents).forEach((comp) => {
  Vue.component(comp, GlobalComponents[comp]);
});

Vue.config.productionTip = false;

const vueApp = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
});

const bootstrap = async () => {
  const { isLoggedIn } = auth;
  if (isLoggedIn) {
    await MarketStatus.fetchMarketStatus();

    const client = await MyProfileUseCases.fetchClient(auth.clientId);
    const clientLevel = client.level;
    if (clientLevel !== "STANDARD") {
      vueApp.$mount("#app").$router.push({ name: "continue-to-app" });
    } else {
      vueApp.$mount("#app");
    }
  } else {
    vueApp.$mount("#app");
  }

  // Call helpers here
  MarketStatus.startPolling();
};

bootstrap();

const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
if (isMobile) {
  store.dispatch("modal/show");
  store.dispatch(
    "modal/setComponent",
    () => import("./components/modals/ContinueInAppModal.vue")
  );
  store.dispatch("modal/setOptions", {
    hideCloseButton: true,
  });
}

initZendeskWidget();
