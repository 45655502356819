import { ClientsRepository } from "./repositories";

interface IGetClient {
  amlValidation: {
    lastUpdated: string;
    result: "KO" | "OK" | "REVIEW";
  };
  contractSigned: true;
  extraInfo: {
    actingOnBehalfOfAThirdPerson: true;
    annualIncome:
      | "BETWEEN_10K_AND_30K"
      | "BETWEEN_1K_AND_10K"
      | "BETWEEN_30K_AND_130K"
      | "HIGHER_THAN_130K"
      | "LOWER_THAN_1K";
    investmentExperience: "EXPERT" | "INVESTOR" | "NONE" | "NOOB";
    occupationalInfo: {
      employment:
        | "EMPLOYED"
        | "RETIRED"
        | "SELF_EMPLOYED"
        | "STUDENT"
        | "UNEMPLOYED";
      position:
        | "ADMINISTRATIVE_OR_WORKER"
        | "HIGHER_MANAGER"
        | "MIDDLE_MANAGER"
        | "TECHNICIAN";
      sector:
        | "AGRICULTURE_LIVESTOCK_AND_FISHING"
        | "BANKING_AND_FINANCIAL_SERVICES"
        | "BUILDING_CLEANING_SERVICES"
        | "COMMUNICATION_PRESS_RADIO_TV_AND_SOCIAL_NETWORKS"
        | "COMPANIES_ORGANIZATIONS_AND_PUBLIC_ADMINISTRATIONS"
        | "CONSULTING_AND_MANAGEMENT"
        | "DIPLOMATIC_CORPS_OR_INTERNATIONAL_ORGANIZATIONS"
        | "EDUCATION"
        | "HOSPITALITY_AND_CATERING"
        | "INDUSTRY_METALLURGY_AND_ENERGY"
        | "JEWELRY_ANTIQUES_AND_NUMISMATICS"
        | "JUSTICE_ADMINISTRATION_ARMED_FORCES_FORCES_AND_SECURITY_CORPS"
        | "LOTTERIES_AND_GAMBLING"
        | "MILITARY_AND_DEFENSE_INDUSTRY"
        | "POLITICAL_PARTIES_BUSINESS_ASSOCIATIONS_OR_LABOR_UNIONS"
        | "REAL_STATE_AND_CONSTRUCTION"
        | "RETAIL_COMMERCE"
        | "SHOWS_AND_SPORTS"
        | "TRANSPORT_AND_DISTRIBUTION";
    };
    publicResponsibilityPerson: boolean;
  };
  featureFlags: Record<string, unknown>;
  hydraId: string;
  id: string;
  identityValidation: {
    id: string;
    identityId: string;
    rejections: [string];
    result: "KO" | "OK" | "PROCESSING";
    signatureInfo: [
      {
        originalDocument: {
          creationDate: string;
          documentId: string;
          name: string;
        };
        signatureId: string;
        signedDocument: {
          creationDate: string;
          documentId: string;
          name: string;
        };
      }
    ];
    verificationId: string;
    videoId: string;
  };
  level: "PRE" | "STANDARD";
  limits: {
    maxBuyAmount: {
      additionalProp1: number;
      additionalProp2: number;
      additionalProp3: number;
    };
    maxBuyDailyTradedAmount: {
      additionalProp1: number;
      additionalProp2: number;
      additionalProp3: number;
    };
    maxBuyShareQuantity: {
      additionalProp1: number;
      additionalProp2: number;
      additionalProp3: number;
    };
    maxSellDailyTradedAmount: {
      additionalProp1: number;
      additionalProp2: number;
      additionalProp3: number;
    };
  };
  nieDocument: {
    status: "NOT_MANDATORY" | "UPLOADED" | "WAITING_TO_BE_UPLOADED";
  };
  pendingGifts: number;
  personalData: {
    citizenship: string;
    countryOfBirth: string;
    countryOfResidence: string;
    dateOfBirth: string;
    fiscalAddress: {
      city: string;
      country: string;
      lane: string;
      laneNumber: string;
      moreInfo: string;
      postalCode: string;
    };
    fiscalIdentification: {
      expiryDate: string;
      number: string;
      type: "NIE" | "NIF" | "PASSPORT";
    };
    identification: {
      expiryDate: number;
      number: string;
      type: "NIE";
    };
    identificationDocumentProvided: true;
    mainAddress: {
      city: string;
      country: "AD";
      lane: string;
      laneNumber: string;
      moreInfo: string;
      postalCode: string;
    };
    name: {
      firstLastName: string;
      firstName: string;
      secondLastName: string;
    };
    othersCitizenship: ["AD"];
  };
  referralCode: string;
  registrationProcessFinished: true;
  status: {
    reasons: [
      {
        info: string;
        type: "ACTING_BEHALF_THIRD_PERSON";
      }
    ];
    timestamp: "2021-11-02T13:57:42.305Z";
    type: "BLOCK";
  };
  tinNumber: string;
  userId: string;
  w8benDocument: {
    createdAt: "2021-11-02T13:57:42.305Z";
    originalDocumentW8ben: {
      createdAt: "2021-11-02T13:57:42.305Z";
      documentId: string;
      name: string;
    };
    signatureId: string;
    signedDocumentW8ben: {
      createdAt: "2021-11-02T13:57:42.305Z";
      documentId: string;
      name: string;
    };
    status: "NOT_MANDATORY";
    updatedAt: "2021-11-02T13:57:42.305Z";
  };
}

export const MyProfileUseCases = {
  async fetchClient(clientId: string): Promise<IGetClient> {
    const { data } = await ClientsRepository.get("/" + clientId);

    return data;
  },
};
