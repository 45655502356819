import { IChartInstance } from "@/components/charts/LPriceChart";
import { ICurrentPrice, IPricesRes, ProductUseCases } from "@/core/Product";
import { IOrder, IPosition, IProduct } from "@/core/types";
import { VuexModule } from "../store";

interface ProductState {
  product: IProduct | undefined;
  logoUrl: string;
  position: IPosition | undefined;
  orders: IOrder[];
  polling: boolean;
  marketStatus?: {
    open: boolean;
    nextOpen?: string;
    nextClose?: string;
  };
  prices: IPricesRes;
  currentPrice: ICurrentPrice;
  ChartInstance?: IChartInstance;
  showVolume: boolean;
  showCandles: boolean;
}

const product: VuexModule<ProductState, undefined> = {
  namespaced: true,
  state: () => ({
    product: undefined,
    logoUrl: "",
    position: undefined,
    orders: [],
    marketStatus: undefined,
    polling: false,
    prices: {
      items: [],
      currency: "USD",
    },
    ChartInstance: undefined,
    currentPrice: {
      ask: 0,
      assetId: "",
      bid: 0,
      change: 0,
      close: 0,
      currency: "",
      high: 0,
      low: 0,
      mid: 0,
      open: 0,
      percentChange: 0,
      percentChangeFromPreviousClose: 0,
      price: 0,
      tradingHalted: false,
      volume: 0,
    },
    showVolume: false,
    showCandles: false,
  }),
  mutations: {
    SET_PRODUCT(state, val) {
      state.product = val;
    },
    SET_LOGOURL(state, val) {
      state.logoUrl = val;
    },
    SET_POSITION(state, val) {
      state.position = val;
    },
    SET_ORDERS(state, val) {
      state.orders = val;
    },
    SET_POLLING(state, val) {
      state.polling = val;
    },
    SET_PRICES(state, val) {
      state.prices = val;
    },
    SET_CHART_INSTANCE(state, val) {
      state.ChartInstance = val;
    },
    PUSH_PRICE(state, val) {
      state.prices.items = [val, ...state.prices.items];
    },
    SET_CURRENT_PRICE(state, val) {
      state.currentPrice = val;
    },
    TOGGLE_VOLUME(state) {
      state.showVolume = !state.showVolume;
    },
    SET_VOLUME(state, val) {
      state.showVolume = val;
    },
    SET_CANDLES(state, val) {
      state.showCandles = val;
    },
  },
  actions: {
    async main({ dispatch }, productId: string) {
      dispatch("fetchPosition", productId);
    },
    async fetchPosition({ commit }, id): Promise<void> {
      try {
        const res = await ProductUseCases.getPosition(id);
        commit("SET_POSITION", res);
      } catch (error) {
        commit("SET_POSITION", {});
      }
    },
    async fetchPrices({ commit }, { id, interval }): Promise<void> {
      const res = await ProductUseCases.getPrices(id, interval);
      commit("SET_PRICES", res);
    },
    async fetchCurrentPrice({ commit }, id): Promise<void> {
      const res = await ProductUseCases.getCurrentPrice(id);
      commit("SET_CURRENT_PRICE", res);
    },
    destroyChart({ commit, state }) {
      state.ChartInstance?.chart.remove();
      commit("SET_CHART_INSTANCE", undefined);
    },

    onVolumeClick({ commit, state }) {
      commit("SET_VOLUME", !state.showVolume);

      if (state.showVolume) {
        state.ChartInstance?.showVolume();
      } else {
        state.ChartInstance?.hideVolume();
      }
    },
    onCandlesClick({ commit, state }) {
      commit("SET_CANDLES", !state.showCandles);

      if (state.showCandles) {
        state.ChartInstance?.showCandles();
      } else {
        state.ChartInstance?.hideCandles();
      }
    },
  },
  getters: {
    hasShares(state): boolean {
      return state.position?.shares > 0;
    },
  },
};

export { product };
