import { SearchUseCases } from "@/core/Search/SearchUseCases";
import { IAssetSearchResult } from "@/core/types";
import { VuexModule } from "../store";

interface SearchState {
  popularAssets: IAssetSearchResult[];
  loading: boolean;
  loadingSearch: boolean;
  searchResults: IAssetSearchResult[];
  totalResults: number;
  query: string;
}

const search: VuexModule<SearchState, undefined> = {
  namespaced: true,
  state: (): SearchState => ({
    loading: false,
    loadingSearch: false,
    popularAssets: [],
    searchResults: [],
    totalResults: 0,
    query: "",
  }),
  mutations: {
    SET_LOADING(state, loading: boolean) {
      state.loading = loading;
    },
    SET_LOADING_SEARCH(state, loading: boolean) {
      state.loadingSearch = loading;
    },
    SET_POPULAR(state, popularAssets: IAssetSearchResult[]) {
      state.popularAssets = popularAssets;
    },
    SET_SEARCH_RESULTS(state, searchResults: IAssetSearchResult[]) {
      state.searchResults = searchResults;
    },
    ADD_SEARCH_RESULTS(state, searchResults: IAssetSearchResult[]) {
      state.searchResults = [...state.searchResults, ...searchResults];
    },
    SET_TOTAL(state, totalResults: number) {
      state.totalResults = totalResults;
    },
    SET_QUERY(state, query: string) {
      state.query = query;
    },
  },
  actions: {
    async fetchPopularAssets({ commit }) {
      commit("SET_LOADING", true);
      const hotAssets = await SearchUseCases.fetchPopularAssets();
      commit("SET_POPULAR", hotAssets);
      commit("SET_LOADING", false);
    },
    async searchAssets({ commit }, q: string) {
      commit("SET_QUERY", q);
      commit("SET_LOADING_SEARCH", true);
      const searchResults = await SearchUseCases.searchAssets(q);

      commit("SET_TOTAL", searchResults.totalItems);
      commit("SET_SEARCH_RESULTS", searchResults.items);
      commit("SET_LOADING_SEARCH", false);
    },
    async loadMore({ commit, state }, q: string) {
      commit("SET_QUERY", q);
      commit("SET_LOADING", true);
      if (state.searchResults.length === state.totalResults) {
        return;
      }
      const searchResults = await SearchUseCases.searchAssets(
        q,
        state.searchResults.length
      );
      commit("SET_TOTAL", searchResults.totalItems);
      commit("ADD_SEARCH_RESULTS", searchResults.items);
      commit("SET_LOADING", false);
    },
  },
  getters: {},
};

export { search };
