import { RestRepository } from "../../base/RestRepository";
import defaultConfig from "../../config";

const ClientsRepositoryV1 = new RestRepository({
  version: "v1",
  isPublic: false,
  path: "clients",
  baseURL: defaultConfig.api.url,
});

const ClientsRepositoryV2 = new RestRepository({
  version: "v2",
  isPublic: false,
  path: "clients",
  baseURL: defaultConfig.api.url,
});

export { ClientsRepositoryV2, ClientsRepositoryV1 };
