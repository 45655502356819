












































import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class BaseInputText extends Vue {
  @Prop(String) readonly name!: string;
  @Prop(String) readonly label!: string;
  @Prop(String) readonly infoMsg!: string;
  @Prop({ type: [String, Error] }) readonly errorMsg!: string | Error;
  @Prop(String) readonly error!: boolean;
  @Prop(String) readonly rules!: string;
  @Prop(Boolean) readonly password!: boolean;
  @Prop(Boolean) readonly number!: boolean;
  @Prop(Boolean) readonly autofocus!: boolean;
  @Prop({ type: String, default: "aggressive" })
  readonly validationMode!: string;
  @Prop(String) readonly prepend!: string;

  get errorState(): boolean {
    return this.error;
  }

  get type(): string {
    if (this.password) return "password";
    if (this.number) return "number";
    else return "text";
  }

  mounted(): void {
    if (this.autofocus) {
      const el = this.$el.querySelector("input");
      el!.focus();
    }
  }
}
