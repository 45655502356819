import { getMarketStatus } from "..";

export const MarketStatus = {
  open: false,
  nextOpen: "",
  nextClose: "",
  isPolling: false,

  async fetchMarketStatus(): Promise<void> {
    const res = await getMarketStatus();
    this.open = res.open;
    this.nextOpen = res.nextOpen;
    this.nextClose = res.nextClose;
  },

  startPolling(timeout = 300000): void {
    if (
      process.env.NODE_ENV === "staging" ||
      process.env.NODE_ENV === "production"
    ) {
      this.isPolling = true;
      this.fetchMarketStatus();
      setInterval(() => {
        this.fetchMarketStatus();
      }, timeout);
    }
  },
};
