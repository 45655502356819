import { formatAmount } from "./formatAmount";
import { formatNumber } from "./formatNumber";

export function formatVolume(v: number): string {
  if (v >= 1000000000)
    return `${(v / 1000000000).toFixed(2).replace(".", ",")} mil M`;
  else if (v >= 1000000)
    return `${(v / 1000000).toFixed(2).replace(".", ",")} M`;
  else return formatNumber(v);
}
