/* eslint-disable @typescript-eslint/no-explicit-any */
import BaseButton from "./BaseButton.vue";
import BaseInputText from "./BaseInputText.vue";
import BaseRadioGroup from "./BaseRadioGroup.vue";
import BaseIcon from "./BaseIcon.vue";
import ModalContainer from "./ModalContainer.vue";
import BaseSpinner from "./BaseSpinner.vue";
import BaseText from "./BaseText.vue";
import BaseAmount from "./BaseAmount.vue";
import BasePercent from "./BasePercent.vue";
import BaseImg from "./BaseImg.vue";
import BaseInputNumber from "./BaseInputNumber.vue";
import DetailCell from "./DetailCell.vue";

export const GlobalComponents: Record<string, any> = {
  BaseButton,
  BaseInputText,
  BaseIcon,
  BaseRadioGroup,
  ModalContainer,
  BaseSpinner,
  BaseText,
  BaseAmount,
  BasePercent,
  BaseImg,
  BaseInputNumber,
  DetailCell,
};
