

















import { Component, Vue } from "vue-property-decorator";
import { MarketStatus } from "../../core/base/MarketStatusService";

interface ILayouts {
  [key: string]: any;
}

@Component({
  components: {},
})
export default class LayoutProvider extends Vue {
  marketStatus = MarketStatus;
  layouts: ILayouts = {
    app: () => import(/* webpackChunkName: "AppLayout" */ "./AppLayout.vue"),
    empty: () =>
      import(/* webpackChunkName: "EmptyLayout" */ "./EmptyLayout.vue"),
    forgotPassword: () =>
      import(
        /* webpackChunkName: "ForgotPasswordLayout" */ "./ForgotPasswordLayout.vue"
      ),
    bSignUp: () =>
      import(/* webpackChunkName: "BSignUpLayout" */ "./BSignUpLayout.vue"),
    orders: () =>
      import(/* webpackChunkName: "OrdersLayout" */ "./OrdersLayout.vue"),
  };

  get layout(): any {
    if (!this.$route?.meta?.layout) return this.layouts["app"];
    else return this.layouts[this.$route.meta.layout];
  }

  get modalComponent(): unknown {
    return this.$store.state.modal.component;
  }

  get isLocalhost(): boolean {
    return window.location.hostname === "localhost";
  }
}
