import { RestRepository } from "../../base/RestRepository";
import defaultConfig from "../../config";

const UsersRepository = new RestRepository({
  version: "v1",
  isPublic: true,
  path: "users",
  baseURL: defaultConfig.api.url,
});

const PrivUsersRepository = new RestRepository({
  version: "v1",
  isPublic: false,
  path: "users",
  baseURL: defaultConfig.api.url,
});

export { UsersRepository, PrivUsersRepository };
