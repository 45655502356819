export type currencyKey = "EUR" | "USD";
export type currencySymbol = "€" | "$";
export type currencySymbolMap = Record<currencyKey, currencySymbol>;

const currencySymbolMap: currencySymbolMap = {
  EUR: "€",
  USD: "$",
};

export const getCurrencySymbol = (name: currencyKey): currencySymbol => {
  return currencySymbolMap[name];
};
