import { RestRepository } from "../../base/RestRepository";
import defaultConfig from "../../config";

const OrdersRepository = new RestRepository({
  version: "v2",
  isPublic: false,
  path: "orders",
  baseURL: defaultConfig.api.url,
});

export { OrdersRepository };
