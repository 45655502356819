




import { Component, Prop, Vue } from "vue-property-decorator";
import { formatNumber } from "@/core/utils/formatNumber";

@Component
export default class BasePercent extends Vue {
  @Prop({
    type: [Number, String],
  })
  readonly val!: number;

  @Prop(Boolean) readonly plain?: boolean;

  get classes(): string {
    if (this.plain) {
      return "text-gray-800";
    } else if (+this.val === 0) {
      return "text-gray-500";
    } else if (this.isNegative) {
      return "text-red-500 nn-triangle nn-triangle-red";
    } else {
      return "text-green-500 nn-triangle nn-triangle-green";
    }
  }

  get isNegative(): boolean {
    return this.val < 0;
  }

  get formattedAmount(): string {
    return formatNumber(+Math.abs(this.val));
  }
}
