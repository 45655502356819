import { rest } from "msw";

export const handlers = [
  // rest.get(/\/prices\//, (req, res, ctx) => {
  //   console.log("TESTING SERVICE WORKER");
  //   console.log(ctx);
  //   return res(ctx.json({}));
  // }),
  // rest.get(/firebasestorage/, (req, res, ctx) => {
  //   return res(ctx.json({}));
  // }),
  rest.get(/markets\/status/, (req, res, ctx) => {
    return res(
      ctx.json({
        nextClose: "2022-04-28T13:15:00Z",
        nextOpen: null,
        open: false,
      })
    );
  }),
  rest.post(/orders/, (req, res, ctx) => {
    const ERROR = false;
    const SIDE = req.body.orderSide; // "BUY" or "SELL"
    const TYPE = req.body.orderType; // "LIMIT" or "MARKET"

    const responses = {
      error: {
        code: 400,
        body: {
          message: "MOCK ERROR",
        },
      },
      MARKET: {
        BUY: {
          code: 201,
          body: {
            id: "622b1ddb5887455ba4c1d7de",
            shares: 1,
            type: "MARKET",
            side: "BUY",
            status: "LIVE",
            assetSnapshot: {
              id: "448c5f9d-8ec4-4b0f-a2c8-ae57cfd1030d",
              companyName: "COMPANY NAME",
              hot: false,
              isin: "US58403P1057",
              price: {
                value: 1000.1,
                currency: "USD",
              },
              symbol: "COMPANY",
              currency: "USD",
            },
            customPrice: null,
            expiresAt: null,
            executionSummary: null,
            createdAt: "2022-03-11T10:00:59.118Z",
            updatedAt: "2022-03-11T10:00:59.118Z",
            estimation: {
              forex: {
                rate: {
                  value: 0.9118,
                  currency: "USD",
                  counterCurrency: "EUR",
                },
                commission: 0.5,
                rateWithCommission: {
                  value: 0.9164,
                  currency: "USD",
                  counterCurrency: "EUR",
                },
              },
              totalAmountInAssetCurrency: {
                value: 1.1,
                currency: "USD",
              },
              totalAmountInClientCurrency: {
                value: 1,
                currency: "EUR",
              },
              totalCostInClientCurrency: {
                value: 2.01,
                currency: "EUR",
              },
              settlementCost: {
                value: 1,
                currency: "EUR",
              },
              forexCost: {
                value: 0.01,
                currency: "EUR",
              },
            },
            hasNote: false,
          },
        },
        SELL: {
          code: 201,
          body: {
            id: "62690b08f4a3b617e5f7eca4",
            shares: 2,
            type: "MARKET",
            side: "SELL",
            status: "LIVE",
            assetSnapshot: {
              id: "31a5ceea-370e-47f1-ae81-6295394049f9",
              companyName: "Acme United Corp.",
              hot: false,
              isin: "US0048161048",
              price: { value: 32.05, currency: "USD" },
              symbol: "ACU",
              currency: "USD",
            },
            customPrice: null,
            expiresAt: null,
            executionSummary: null,
            createdAt: "2022-04-27T09:21:12.902Z",
            updatedAt: "2022-04-27T09:21:12.902Z",
            estimation: {
              forex: {
                rate: {
                  value: 0.9433,
                  currency: "USD",
                  counterCurrency: "EUR",
                },
                commission: 0.5,
                rateWithCommission: {
                  value: 0.9386,
                  currency: "USD",
                  counterCurrency: "EUR",
                },
              },
              totalAmountInAssetCurrency: { value: 62.94, currency: "USD" },
              totalAmountInClientCurrency: { value: 59.37, currency: "EUR" },
              totalCostInClientCurrency: { value: 58.08, currency: "EUR" },
              settlementCost: { value: 1, currency: "EUR" },
              forexCost: { value: 0.3, currency: "EUR" },
            },
            clientId: "6156cdef94ff15083e59a0d0",
            hasNote: false,
          },
        },
      },
    };
    if (ERROR) {
      return res(
        ctx.status(responses.error.code),
        ctx.json(responses.error.body)
      );
    } else {
      return res(
        ctx.status(responses[TYPE][SIDE].code),
        ctx.json(responses[TYPE][SIDE].body)
      );
    }
  }),
  rest.get(/v1\/prices\/\?assetId/, (req, res, ctx) => {
    const json = [
      {
        assetId: "154bdbe1-adc6-42f3-81d1-ae372f8ef3ff",
        currency: "USD",
        items: [
          {
            value: 86.1,
            time: "2022-05-02T13:30:00Z",
            open: 85.63,
            close: 86.1,
            volume: 96232,
            high: 86.77,
            low: 85.62,
            startTime: "2022-05-02T13:30:00Z",
            endTime: "2022-05-02T13:35:00Z",
            priceChange: -3.74,
            percentChange: -4.16,
          },
          {
            value: 86.89,
            time: "2022-05-02T13:35:00Z",
            open: 86.1,
            close: 86.89,
            volume: 82386,
            high: 86.94,
            low: 85.59,
            startTime: "2022-05-02T13:35:00Z",
            endTime: "2022-05-02T13:40:00Z",
            priceChange: -2.95,
            percentChange: -3.28,
          },
          {
            value: 88.01,
            time: "2022-05-02T13:40:00Z",
            open: 86.81,
            close: 88.01,
            volume: 82347,
            high: 88.21,
            low: 86.46,
            startTime: "2022-05-02T13:40:00Z",
            endTime: "2022-05-02T13:45:00Z",
            priceChange: -1.83,
            percentChange: -2.04,
          },
          {
            value: 87.53,
            time: "2022-05-02T13:45:00Z",
            open: 87.96,
            close: 87.53,
            volume: 83899,
            high: 88.49,
            low: 87.46,
            startTime: "2022-05-02T13:45:00Z",
            endTime: "2022-05-02T13:50:00Z",
            priceChange: -2.31,
            percentChange: -2.57,
          },
          {
            value: 88.01,
            time: "2022-05-02T13:50:00Z",
            open: 87.52,
            close: 88.01,
            volume: 68098,
            high: 88.08,
            low: 87.32,
            startTime: "2022-05-02T13:50:00Z",
            endTime: "2022-05-02T13:55:00Z",
            priceChange: -1.83,
            percentChange: -2.04,
          },
          {
            value: 87.86,
            time: "2022-05-02T13:55:00Z",
            open: 87.98,
            close: 87.86,
            volume: 50576,
            high: 88.52,
            low: 87.72,
            startTime: "2022-05-02T13:55:00Z",
            endTime: "2022-05-02T14:00:00Z",
            priceChange: -1.98,
            percentChange: -2.2,
          },
          {
            value: 87.8,
            time: "2022-05-02T14:00:00Z",
            open: 87.87,
            close: 87.8,
            volume: 78494,
            high: 88.04,
            low: 87.3,
            startTime: "2022-05-02T14:00:00Z",
            endTime: "2022-05-02T14:05:00Z",
            priceChange: -2.04,
            percentChange: -2.27,
          },
          {
            value: 87.16,
            time: "2022-05-02T14:05:00Z",
            open: 87.77,
            close: 87.16,
            volume: 82042,
            high: 87.79,
            low: 87.07,
            startTime: "2022-05-02T14:05:00Z",
            endTime: "2022-05-02T14:10:00Z",
            priceChange: -2.68,
            percentChange: -2.98,
          },
          {
            value: 86.58,
            time: "2022-05-02T14:10:00Z",
            open: 87.12,
            close: 86.58,
            volume: 55936,
            high: 87.46,
            low: 86.52,
            startTime: "2022-05-02T14:10:00Z",
            endTime: "2022-05-02T14:15:00Z",
            priceChange: -3.26,
            percentChange: -3.63,
          },
          {
            value: 87.18,
            time: "2022-05-02T14:15:00Z",
            open: 86.59,
            close: 87.18,
            volume: 82404,
            high: 87.26,
            low: 86.51,
            startTime: "2022-05-02T14:15:00Z",
            endTime: "2022-05-02T14:20:00Z",
            priceChange: -2.66,
            percentChange: -2.96,
          },
          {
            value: 87.54,
            time: "2022-05-02T14:20:00Z",
            open: 87.16,
            close: 87.54,
            volume: 54673,
            high: 87.72,
            low: 87,
            startTime: "2022-05-02T14:20:00Z",
            endTime: "2022-05-02T14:25:00Z",
            priceChange: -2.3,
            percentChange: -2.56,
          },
          {
            value: 88.33,
            time: "2022-05-02T14:25:00Z",
            open: 87.55,
            close: 88.33,
            volume: 72205,
            high: 88.48,
            low: 87.43,
            startTime: "2022-05-02T14:25:00Z",
            endTime: "2022-05-02T14:30:00Z",
            priceChange: -1.51,
            percentChange: -1.68,
          },
          {
            value: 88.54,
            time: "2022-05-02T14:30:00Z",
            open: 88.34,
            close: 88.54,
            volume: 47699,
            high: 88.77,
            low: 88.33,
            startTime: "2022-05-02T14:30:00Z",
            endTime: "2022-05-02T14:35:00Z",
            priceChange: -1.3,
            percentChange: -1.45,
          },
          {
            value: 88.17,
            time: "2022-05-02T14:35:00Z",
            open: 88.52,
            close: 88.17,
            volume: 48575,
            high: 88.67,
            low: 88.03,
            startTime: "2022-05-02T14:35:00Z",
            endTime: "2022-05-02T14:40:00Z",
            priceChange: -1.67,
            percentChange: -1.86,
          },
          {
            value: 87.89,
            time: "2022-05-02T14:40:00Z",
            open: 88.16,
            close: 87.89,
            volume: 49946,
            high: 88.53,
            low: 87.8,
            startTime: "2022-05-02T14:40:00Z",
            endTime: "2022-05-02T14:45:00Z",
            priceChange: -1.95,
            percentChange: -2.17,
          },
          {
            value: 87.63,
            time: "2022-05-02T14:45:00Z",
            open: 87.89,
            close: 87.63,
            volume: 43165,
            high: 87.91,
            low: 87.48,
            startTime: "2022-05-02T14:45:00Z",
            endTime: "2022-05-02T14:50:00Z",
            priceChange: -2.21,
            percentChange: -2.46,
          },
          {
            value: 87.4,
            time: "2022-05-02T14:50:00Z",
            open: 87.65,
            close: 87.4,
            volume: 33749,
            high: 87.71,
            low: 87.27,
            startTime: "2022-05-02T14:50:00Z",
            endTime: "2022-05-02T14:55:00Z",
            priceChange: -2.44,
            percentChange: -2.72,
          },
          {
            value: 87.1,
            time: "2022-05-02T14:55:00Z",
            open: 87.34,
            close: 87.1,
            volume: 36992,
            high: 87.8,
            low: 86.97,
            startTime: "2022-05-02T14:55:00Z",
            endTime: "2022-05-02T15:00:00Z",
            priceChange: -2.74,
            percentChange: -3.05,
          },
          {
            value: 86.86,
            time: "2022-05-02T15:00:00Z",
            open: 87.11,
            close: 86.86,
            volume: 29632,
            high: 87.28,
            low: 86.79,
            startTime: "2022-05-02T15:00:00Z",
            endTime: "2022-05-02T15:05:00Z",
            priceChange: -2.98,
            percentChange: -3.32,
          },
          {
            value: 87.22,
            time: "2022-05-02T15:05:00Z",
            open: 86.835,
            close: 87.22,
            volume: 57277,
            high: 87.3,
            low: 86.835,
            startTime: "2022-05-02T15:05:00Z",
            endTime: "2022-05-02T15:10:00Z",
            priceChange: -2.62,
            percentChange: -2.92,
          },
          {
            value: 87.76,
            time: "2022-05-02T15:10:00Z",
            open: 87.24,
            close: 87.76,
            volume: 32458,
            high: 87.78,
            low: 87.07,
            startTime: "2022-05-02T15:10:00Z",
            endTime: "2022-05-02T15:15:00Z",
            priceChange: -2.08,
            percentChange: -2.32,
          },
          {
            value: 86.78,
            time: "2022-05-02T15:15:00Z",
            open: 87.74,
            close: 86.78,
            volume: 37409,
            high: 87.77,
            low: 86.73,
            startTime: "2022-05-02T15:15:00Z",
            endTime: "2022-05-02T15:20:00Z",
            priceChange: -3.06,
            percentChange: -3.41,
          },
          {
            value: 87.07,
            time: "2022-05-02T15:20:00Z",
            open: 86.72,
            close: 87.07,
            volume: 29344,
            high: 87.07,
            low: 86.69,
            startTime: "2022-05-02T15:20:00Z",
            endTime: "2022-05-02T15:25:00Z",
            priceChange: -2.77,
            percentChange: -3.08,
          },
          {
            value: 87.26,
            time: "2022-05-02T15:25:00Z",
            open: 87.05,
            close: 87.26,
            volume: 36207,
            high: 87.41,
            low: 86.85,
            startTime: "2022-05-02T15:25:00Z",
            endTime: "2022-05-02T15:30:00Z",
            priceChange: -2.58,
            percentChange: -2.87,
          },
          {
            value: 87.41,
            time: "2022-05-02T15:30:00Z",
            open: 87.32,
            close: 87.41,
            volume: 39600,
            high: 87.61,
            low: 87.04,
            startTime: "2022-05-02T15:30:00Z",
            endTime: "2022-05-02T15:35:00Z",
            priceChange: -2.43,
            percentChange: -2.7,
          },
          {
            value: 86.95,
            time: "2022-05-02T15:35:00Z",
            open: 87.44,
            close: 86.95,
            volume: 26776,
            high: 87.5,
            low: 86.84,
            startTime: "2022-05-02T15:35:00Z",
            endTime: "2022-05-02T15:40:00Z",
            priceChange: -2.89,
            percentChange: -3.22,
          },
          {
            value: 86.94,
            time: "2022-05-02T15:40:00Z",
            open: 86.9,
            close: 86.94,
            volume: 18895,
            high: 87.31,
            low: 86.76,
            startTime: "2022-05-02T15:40:00Z",
            endTime: "2022-05-02T15:45:00Z",
            priceChange: -2.9,
            percentChange: -3.23,
          },
          {
            value: 86.87,
            time: "2022-05-02T15:45:00Z",
            open: 86.98,
            close: 86.87,
            volume: 19638,
            high: 87.13,
            low: 86.77,
            startTime: "2022-05-02T15:45:00Z",
            endTime: "2022-05-02T15:50:00Z",
            priceChange: -2.97,
            percentChange: -3.31,
          },
          {
            value: 87.2,
            time: "2022-05-02T15:50:00Z",
            open: 86.82,
            close: 87.2,
            volume: 30240,
            high: 87.26,
            low: 86.82,
            startTime: "2022-05-02T15:50:00Z",
            endTime: "2022-05-02T15:55:00Z",
            priceChange: -2.64,
            percentChange: -2.94,
          },
          {
            value: 87.61,
            time: "2022-05-02T15:55:00Z",
            open: 87.21,
            close: 87.61,
            volume: 32148,
            high: 87.67,
            low: 87.1,
            startTime: "2022-05-02T15:55:00Z",
            endTime: "2022-05-02T16:00:00Z",
            priceChange: -2.23,
            percentChange: -2.48,
          },
          {
            value: 87.35,
            time: "2022-05-02T16:00:00Z",
            open: 87.62,
            close: 87.35,
            volume: 31338,
            high: 87.83,
            low: 87.15,
            startTime: "2022-05-02T16:00:00Z",
            endTime: "2022-05-02T16:05:00Z",
            priceChange: -2.49,
            percentChange: -2.77,
          },
          {
            value: 87.6,
            time: "2022-05-02T16:05:00Z",
            open: 87.33,
            close: 87.6,
            volume: 21859,
            high: 87.64,
            low: 87.2,
            startTime: "2022-05-02T16:05:00Z",
            endTime: "2022-05-02T16:10:00Z",
            priceChange: -2.24,
            percentChange: -2.49,
          },
          {
            value: 87.91,
            time: "2022-05-02T16:10:00Z",
            open: 87.58,
            close: 87.91,
            volume: 16643,
            high: 88,
            low: 87.51,
            startTime: "2022-05-02T16:10:00Z",
            endTime: "2022-05-02T16:15:00Z",
            priceChange: -1.93,
            percentChange: -2.15,
          },
          {
            value: 88.28,
            time: "2022-05-02T16:15:00Z",
            open: 87.87,
            close: 88.28,
            volume: 21735,
            high: 88.28,
            low: 87.75,
            startTime: "2022-05-02T16:15:00Z",
            endTime: "2022-05-02T16:20:00Z",
            priceChange: -1.56,
            percentChange: -1.74,
          },
          {
            value: 88.15,
            time: "2022-05-02T16:20:00Z",
            open: 88.29,
            close: 88.15,
            volume: 26960,
            high: 88.48,
            low: 88.08,
            startTime: "2022-05-02T16:20:00Z",
            endTime: "2022-05-02T16:25:00Z",
            priceChange: -1.69,
            percentChange: -1.88,
          },
          {
            value: 88.2,
            time: "2022-05-02T16:25:00Z",
            open: 88.17,
            close: 88.2,
            volume: 27043,
            high: 88.32,
            low: 87.98,
            startTime: "2022-05-02T16:25:00Z",
            endTime: "2022-05-02T16:30:00Z",
            priceChange: -1.64,
            percentChange: -1.83,
          },
          {
            value: 88.31,
            time: "2022-05-02T16:30:00Z",
            open: 88.21,
            close: 88.31,
            volume: 26545,
            high: 88.32,
            low: 87.9,
            startTime: "2022-05-02T16:30:00Z",
            endTime: "2022-05-02T16:35:00Z",
            priceChange: -1.53,
            percentChange: -1.7,
          },
          {
            value: 87.82,
            time: "2022-05-02T16:35:00Z",
            open: 88.28,
            close: 87.82,
            volume: 28703,
            high: 88.42,
            low: 87.78,
            startTime: "2022-05-02T16:35:00Z",
            endTime: "2022-05-02T16:40:00Z",
            priceChange: -2.02,
            percentChange: -2.25,
          },
          {
            value: 87.79,
            time: "2022-05-02T16:40:00Z",
            open: 87.83,
            close: 87.79,
            volume: 19712,
            high: 88.01,
            low: 87.65,
            startTime: "2022-05-02T16:40:00Z",
            endTime: "2022-05-02T16:45:00Z",
            priceChange: -2.05,
            percentChange: -2.28,
          },
          {
            value: 87.58,
            time: "2022-05-02T16:45:00Z",
            open: 87.77,
            close: 87.58,
            volume: 20748,
            high: 87.84,
            low: 87.56,
            startTime: "2022-05-02T16:45:00Z",
            endTime: "2022-05-02T16:50:00Z",
            priceChange: -2.26,
            percentChange: -2.52,
          },
          {
            value: 87.39,
            time: "2022-05-02T16:50:00Z",
            open: 87.55,
            close: 87.39,
            volume: 22923,
            high: 87.68,
            low: 87.33,
            startTime: "2022-05-02T16:50:00Z",
            endTime: "2022-05-02T16:55:00Z",
            priceChange: -2.45,
            percentChange: -2.73,
          },
          {
            value: 86.98,
            time: "2022-05-02T16:55:00Z",
            open: 87.4,
            close: 86.98,
            volume: 29150,
            high: 87.49,
            low: 86.91,
            startTime: "2022-05-02T16:55:00Z",
            endTime: "2022-05-02T17:00:00Z",
            priceChange: -2.86,
            percentChange: -3.18,
          },
          {
            value: 86.69,
            time: "2022-05-02T17:00:00Z",
            open: 87,
            close: 86.69,
            volume: 27901,
            high: 87,
            low: 86.55,
            startTime: "2022-05-02T17:00:00Z",
            endTime: "2022-05-02T17:05:00Z",
            priceChange: -3.15,
            percentChange: -3.51,
          },
          {
            value: 86.68,
            time: "2022-05-02T17:05:00Z",
            open: 86.69,
            close: 86.68,
            volume: 34938,
            high: 86.97,
            low: 86.61,
            startTime: "2022-05-02T17:05:00Z",
            endTime: "2022-05-02T17:10:00Z",
            priceChange: -3.16,
            percentChange: -3.52,
          },
          {
            value: 86.57,
            time: "2022-05-02T17:10:00Z",
            open: 86.63,
            close: 86.57,
            volume: 31852,
            high: 86.95,
            low: 86.47,
            startTime: "2022-05-02T17:10:00Z",
            endTime: "2022-05-02T17:15:00Z",
            priceChange: -3.27,
            percentChange: -3.64,
          },
          {
            value: 86.39,
            time: "2022-05-02T17:15:00Z",
            open: 86.57,
            close: 86.39,
            volume: 14970,
            high: 86.57,
            low: 86.28,
            startTime: "2022-05-02T17:15:00Z",
            endTime: "2022-05-02T17:20:00Z",
            priceChange: -3.45,
            percentChange: -3.84,
          },
          {
            value: 86.19,
            time: "2022-05-02T17:20:00Z",
            open: 86.36,
            close: 86.19,
            volume: 14857,
            high: 86.36,
            low: 86.06,
            startTime: "2022-05-02T17:20:00Z",
            endTime: "2022-05-02T17:25:00Z",
            priceChange: -3.65,
            percentChange: -4.06,
          },
          {
            value: 86.3,
            time: "2022-05-02T17:25:00Z",
            open: 86.21,
            close: 86.3,
            volume: 19588,
            high: 86.42,
            low: 86.1,
            startTime: "2022-05-02T17:25:00Z",
            endTime: "2022-05-02T17:30:00Z",
            priceChange: -3.54,
            percentChange: -3.94,
          },
          {
            value: 86.35,
            time: "2022-05-02T17:30:00Z",
            open: 86.27,
            close: 86.35,
            volume: 35525,
            high: 86.64,
            low: 86.16,
            startTime: "2022-05-02T17:30:00Z",
            endTime: "2022-05-02T17:35:00Z",
            priceChange: -3.49,
            percentChange: -3.88,
          },
          {
            value: 86.03,
            time: "2022-05-02T17:35:00Z",
            open: 86.33,
            close: 86.03,
            volume: 26594,
            high: 86.49,
            low: 86.01,
            startTime: "2022-05-02T17:35:00Z",
            endTime: "2022-05-02T17:40:00Z",
            priceChange: -3.81,
            percentChange: -4.24,
          },
          {
            value: 85.63,
            time: "2022-05-02T17:40:00Z",
            open: 86.04,
            close: 85.63,
            volume: 26721,
            high: 86.16,
            low: 85.625,
            startTime: "2022-05-02T17:40:00Z",
            endTime: "2022-05-02T17:45:00Z",
            priceChange: -4.21,
            percentChange: -4.69,
          },
          {
            value: 85.34,
            time: "2022-05-02T17:45:00Z",
            open: 85.64,
            close: 85.34,
            volume: 39329,
            high: 85.73,
            low: 85.33,
            startTime: "2022-05-02T17:45:00Z",
            endTime: "2022-05-02T17:50:00Z",
            priceChange: -4.5,
            percentChange: -5.01,
          },
          {
            value: 85.79,
            time: "2022-05-02T17:50:00Z",
            open: 85.33,
            close: 85.79,
            volume: 14836,
            high: 85.81,
            low: 85.33,
            startTime: "2022-05-02T17:50:00Z",
            endTime: "2022-05-02T17:55:00Z",
            priceChange: -4.05,
            percentChange: -4.51,
          },
          {
            value: 86.13,
            time: "2022-05-02T17:55:00Z",
            open: 85.78,
            close: 86.13,
            volume: 21320,
            high: 86.17,
            low: 85.76,
            startTime: "2022-05-02T17:55:00Z",
            endTime: "2022-05-02T18:00:00Z",
            priceChange: -3.71,
            percentChange: -4.13,
          },
          {
            value: 86.67,
            time: "2022-05-02T18:00:00Z",
            open: 86.11,
            close: 86.67,
            volume: 22396,
            high: 86.68,
            low: 85.99,
            startTime: "2022-05-02T18:00:00Z",
            endTime: "2022-05-02T18:05:00Z",
            priceChange: -3.17,
            percentChange: -3.53,
          },
          {
            value: 86.38,
            time: "2022-05-02T18:05:00Z",
            open: 86.63,
            close: 86.38,
            volume: 17847,
            high: 86.73,
            low: 86.3,
            startTime: "2022-05-02T18:05:00Z",
            endTime: "2022-05-02T18:10:00Z",
            priceChange: -3.46,
            percentChange: -3.85,
          },
          {
            value: 86.04,
            time: "2022-05-02T18:10:00Z",
            open: 86.37,
            close: 86.04,
            volume: 23728,
            high: 86.48,
            low: 86.02,
            startTime: "2022-05-02T18:10:00Z",
            endTime: "2022-05-02T18:15:00Z",
            priceChange: -3.8,
            percentChange: -4.23,
          },
          {
            value: 85.76,
            time: "2022-05-02T18:15:00Z",
            open: 86.03,
            close: 85.76,
            volume: 16419,
            high: 86.09,
            low: 85.66,
            startTime: "2022-05-02T18:15:00Z",
            endTime: "2022-05-02T18:20:00Z",
            priceChange: -4.08,
            percentChange: -4.54,
          },
          {
            value: 85.98,
            time: "2022-05-02T18:20:00Z",
            open: 85.75,
            close: 85.98,
            volume: 14569,
            high: 86.13,
            low: 85.62,
            startTime: "2022-05-02T18:20:00Z",
            endTime: "2022-05-02T18:25:00Z",
            priceChange: -3.86,
            percentChange: -4.3,
          },
          {
            value: 85.92,
            time: "2022-05-02T18:25:00Z",
            open: 85.95,
            close: 85.92,
            volume: 20061,
            high: 86.07,
            low: 85.81,
            startTime: "2022-05-02T18:25:00Z",
            endTime: "2022-05-02T18:30:00Z",
            priceChange: -3.92,
            percentChange: -4.36,
          },
          {
            value: 86.41,
            time: "2022-05-02T18:30:00Z",
            open: 85.87,
            close: 86.41,
            volume: 21192,
            high: 86.5,
            low: 85.87,
            startTime: "2022-05-02T18:30:00Z",
            endTime: "2022-05-02T18:35:00Z",
            priceChange: -3.43,
            percentChange: -3.82,
          },
          {
            value: 86.31,
            time: "2022-05-02T18:35:00Z",
            open: 86.37,
            close: 86.31,
            volume: 17459,
            high: 86.49,
            low: 86.19,
            startTime: "2022-05-02T18:35:00Z",
            endTime: "2022-05-02T18:40:00Z",
            priceChange: -3.53,
            percentChange: -3.93,
          },
          {
            value: 86.17,
            time: "2022-05-02T18:40:00Z",
            open: 86.32,
            close: 86.17,
            volume: 23603,
            high: 86.47,
            low: 85.99,
            startTime: "2022-05-02T18:40:00Z",
            endTime: "2022-05-02T18:45:00Z",
            priceChange: -3.67,
            percentChange: -4.09,
          },
          {
            value: 86.88,
            time: "2022-05-02T18:45:00Z",
            open: 86.19,
            close: 86.88,
            volume: 23686,
            high: 86.93,
            low: 86.19,
            startTime: "2022-05-02T18:45:00Z",
            endTime: "2022-05-02T18:50:00Z",
            priceChange: -2.96,
            percentChange: -3.29,
          },
          {
            value: 87.12,
            time: "2022-05-02T18:50:00Z",
            open: 86.86,
            close: 87.12,
            volume: 35043,
            high: 87.34,
            low: 86.7,
            startTime: "2022-05-02T18:50:00Z",
            endTime: "2022-05-02T18:55:00Z",
            priceChange: -2.72,
            percentChange: -3.03,
          },
          {
            value: 86.83,
            time: "2022-05-02T18:55:00Z",
            open: 87.22,
            close: 86.83,
            volume: 18215,
            high: 87.22,
            low: 86.64,
            startTime: "2022-05-02T18:55:00Z",
            endTime: "2022-05-02T19:00:00Z",
            priceChange: -3.01,
            percentChange: -3.35,
          },
          {
            value: 87.15,
            time: "2022-05-02T19:00:00Z",
            open: 86.81,
            close: 87.15,
            volume: 32603,
            high: 87.27,
            low: 86.7,
            startTime: "2022-05-02T19:00:00Z",
            endTime: "2022-05-02T19:05:00Z",
            priceChange: -2.69,
            percentChange: -2.99,
          },
          {
            value: 87.45,
            time: "2022-05-02T19:05:00Z",
            open: 87.17,
            close: 87.45,
            volume: 32375,
            high: 87.62,
            low: 86.95,
            startTime: "2022-05-02T19:05:00Z",
            endTime: "2022-05-02T19:10:00Z",
            priceChange: -2.39,
            percentChange: -2.66,
          },
          {
            value: 88.04,
            time: "2022-05-02T19:10:00Z",
            open: 87.49,
            close: 88.04,
            volume: 30673,
            high: 88.08,
            low: 87.49,
            startTime: "2022-05-02T19:10:00Z",
            endTime: "2022-05-02T19:15:00Z",
            priceChange: -1.8,
            percentChange: -2,
          },
          {
            value: 88.12,
            time: "2022-05-02T19:15:00Z",
            open: 88.01,
            close: 88.12,
            volume: 21066,
            high: 88.28,
            low: 87.87,
            startTime: "2022-05-02T19:15:00Z",
            endTime: "2022-05-02T19:20:00Z",
            priceChange: -1.72,
            percentChange: -1.91,
          },
          {
            value: 88.56,
            time: "2022-05-02T19:20:00Z",
            open: 88.14,
            close: 88.56,
            volume: 17975,
            high: 88.6,
            low: 88.13,
            startTime: "2022-05-02T19:20:00Z",
            endTime: "2022-05-02T19:25:00Z",
            priceChange: -1.28,
            percentChange: -1.42,
          },
          {
            value: 88.385,
            time: "2022-05-02T19:25:00Z",
            open: 88.55,
            close: 88.385,
            volume: 24496,
            high: 88.71,
            low: 88.22,
            startTime: "2022-05-02T19:25:00Z",
            endTime: "2022-05-02T19:30:00Z",
            priceChange: -1.46,
            percentChange: -1.62,
          },
          {
            value: 88.99,
            time: "2022-05-02T19:30:00Z",
            open: 88.38,
            close: 88.99,
            volume: 30531,
            high: 88.99,
            low: 88.37,
            startTime: "2022-05-02T19:30:00Z",
            endTime: "2022-05-02T19:35:00Z",
            priceChange: -0.85,
            percentChange: -0.95,
          },
          {
            value: 88.76,
            time: "2022-05-02T19:35:00Z",
            open: 88.98,
            close: 88.76,
            volume: 25808,
            high: 89.06,
            low: 88.62,
            startTime: "2022-05-02T19:35:00Z",
            endTime: "2022-05-02T19:40:00Z",
            priceChange: -1.08,
            percentChange: -1.2,
          },
          {
            value: 88.53,
            time: "2022-05-02T19:40:00Z",
            open: 88.82,
            close: 88.53,
            volume: 40662,
            high: 89.04,
            low: 88.51,
            startTime: "2022-05-02T19:40:00Z",
            endTime: "2022-05-02T19:45:00Z",
            priceChange: -1.31,
            percentChange: -1.46,
          },
          {
            value: 89.52,
            time: "2022-05-02T19:45:00Z",
            open: 88.54,
            close: 89.52,
            volume: 67564,
            high: 89.54,
            low: 88.47,
            startTime: "2022-05-02T19:45:00Z",
            endTime: "2022-05-02T19:50:00Z",
            priceChange: -0.32,
            percentChange: -0.36,
          },
          {
            value: 90.09,
            time: "2022-05-02T19:50:00Z",
            open: 89.53,
            close: 90.09,
            volume: 144507,
            high: 90.36,
            low: 89.42,
            startTime: "2022-05-02T19:50:00Z",
            endTime: "2022-05-02T19:55:00Z",
            priceChange: 0.25,
            percentChange: 0.28,
          },
          {
            value: 89.86,
            time: "2022-05-02T19:55:00Z",
            open: 90.08,
            close: 89.86,
            volume: 97973,
            high: 90.09,
            low: 89.77,
            startTime: "2022-05-02T19:55:00Z",
            endTime: "2022-05-02T20:00:00Z",
            priceChange: 0.02,
            percentChange: 0.02,
          },
          {
            value: 89.86,
            time: "2022-05-02T20:00:00Z",
            open: 89.86,
            close: 89.86,
            volume: 97973,
            high: 90.09,
            low: 89.77,
            startTime: "2022-05-02T20:00:00Z",
            endTime: "2022-05-02T20:05:00Z",
            priceChange: 0.02,
            percentChange: 0.02,
          },
        ],
        totalItems: 79,
      },
    ];

    const res3results = {
      assetId: "hi",
      currency: "USD",
      items: [
        {
          value: 86.1,
          time: "2022-05-02T13:30:00Z",
          open: 85.63,
          close: 86.1,
          volume: 96232,
          high: 86.77,
          low: 85.62,
          startTime: "2022-05-02T13:30:00Z",
          endTime: "2022-05-02T13:35:00Z",
          priceChange: -3.74,
          percentChange: -4.16,
        },
        {
          value: 86.89,
          time: "2022-05-02T13:35:00Z",
          open: 86.1,
          close: 86.89,
          volume: 82386,
          high: 86.94,
          low: 85.59,
          startTime: "2022-05-02T13:35:00Z",
          endTime: "2022-05-02T13:40:00Z",
          priceChange: -2.95,
          percentChange: -3.28,
        },
        {
          value: 88.01,
          time: "2022-05-02T13:40:00Z",
          open: 86.81,
          close: 88.01,
          volume: 82347,
          high: 88.21,
          low: 86.46,
          startTime: "2022-05-02T13:40:00Z",
          endTime: "2022-05-02T13:45:00Z",
          priceChange: -1.83,
          percentChange: -2.04,
        },
      ],
      totalItems: 3,
    };

    // return res(ctx.json(res3results));
  }),
];
