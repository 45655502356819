









import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class BaseIcon extends Vue {
  @Prop(String)
  readonly icon!: string;
  @Prop(Boolean)
  readonly solid!: boolean;
  @Prop(Boolean)
  readonly whiteBall!: boolean;
  @Prop(Boolean)
  readonly brand!: boolean;

  get wrapperClasses(): Record<string, boolean> {
    return {
      "bg-white": this.whiteBall,
      "h-12 w-12": this.whiteBall,
      "text-primary": this.whiteBall,
      round: this.whiteBall,
    };
  }

  get type(): "far" | "fas" | "fab" {
    if (this.solid) return "fas";
    else if (this.brand) return "fab";
    else return "far";
  }
}
