const body = document.querySelector("body");
const container = document.createElement("div");

const checkbox = document.createElement("input");
checkbox.type = "checkbox";
checkbox.id = "mocks-checkbox";

const label = document.createElement("label");
label.htmlFor = "mocks-checkbox";
label.innerText = "Mocks";

export const onMocksChange = (callback) => {
  container.appendChild(checkbox);
  container.appendChild(label);
  body.appendChild(container);
  container.style.position = "fixed";
  container.style.bottom = "0";
  container.style.left = "0";
  container.style.padding = "10px";
  container.style.backgroundColor = "#fff";
  container.style.zIndex = "9999";
  checkbox.style.marginRight = "10px";
  checkbox.style.cursor = "pointer";

  checkbox.addEventListener("change", () => {
    callback(checkbox.checked);
  });
};
