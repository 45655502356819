




import { Component, Vue, Watch } from "vue-property-decorator";

@Component({})
export default class BaseImg extends Vue {
  showImage = false;
  src = "";

  mounted(): void {
    if ("IntersectionObserver" in window) {
      const inOb = new IntersectionObserver((entries) => {
        const image = entries[0];

        if (image.isIntersecting) {
          this.showImage = true;
        }
      }, {});

      inOb.observe(this.$el);
    }
  }

  @Watch("showImage")
  onShowImageChange(val: boolean): void {
    if (val) this.src = this.$attrs.src;
  }
}
