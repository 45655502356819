import { initializeApp } from "firebase/app";
import { getStorage, ref, getDownloadURL } from "firebase/storage";
import config from "../config";

const firebaseApp = initializeApp(config.firebase);
const storage = getStorage(firebaseApp);

const env = process.env.NODE_ENV || "";
const envs = ["production", "staging"];

// Small effort to prevent fetching logos from firebase all the time.
const images: Record<string, string | undefined> = {};

const getAssetURL = async (name: string): Promise<string | undefined> => {
  if (!name) return undefined;
  if (envs.includes(env)) {
    if (!images[name]) {
      const formattedName = name.replace(/\//g, "-");
      const assetRef = ref(storage, "assets/" + formattedName + ".png");
      const url = await getDownloadURL(assetRef).catch((e) =>
        console.error("Failed to load asset URL", e)
      );
      images[name] = url || undefined;
      return url || undefined;
    } else {
      return images[name];
    }
  } else {
    return undefined;
  }
};

export { firebaseApp, getAssetURL };
