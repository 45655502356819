import {
  IEstimationRes,
  ISendOrderRes,
  OrdersUseCases,
} from "@/core/Orders/OrdersUseCases";
import { VuexModule } from "../store";

interface SellState {
  asset?: any;
  loading: boolean;
  prices?: IEstimationRes;
  currentShareTotal?: number;
  estimate?: IEstimationRes;
  orderRes?: ISendOrderRes;
}

const sell: VuexModule<SellState, undefined> = {
  namespaced: true,
  state: () => ({
    asset: undefined,
    loading: true,
    prices: undefined,
    estimate: undefined,
    orderRes: undefined,
  }),
  mutations: {
    SET_ASSET(state, val: unknown) {
      state.asset = val;
    },
    SET_PRICES(state, val: IEstimationRes) {
      state.prices = val;
    },
    SET_LOADING(state, val: boolean) {
      state.loading = val;
    },
    SET_CURRENT_SHARE_TOTAL(state, val: number) {
      state.currentShareTotal = val;
    },
    SET_ESTIMATE(state, val: IEstimationRes) {
      state.estimate = val;
    },
    SET_ORDER_RES(state, val: ISendOrderRes) {
      state.orderRes = val;
    },
  },
  actions: {
    async sendSellOrder({ commit }, { assetId, shares }) {
      const data = await OrdersUseCases.sendMarketSellOrder({
        assetId,
        shares,
      });
      commit("SET_ORDER_RES", data);
    },
    async fetchAsset({ commit }, id: string) {
      const data = await OrdersUseCases.fetchCompany(id);
      commit("SET_ASSET", data);
    },
    async fetchPrices({ commit }, id: string) {
      const data = await OrdersUseCases.fetchEstimate({
        assetId: id,
        shares: 1,
        orderSide: "SELL",
        orderType: "MARKET",
      });
      commit("SET_PRICES", data);
    },
    async fetchCurrentShareTotal({ commit }, assetId: string) {
      const data = await OrdersUseCases.fetchShareTotal(assetId);
      commit("SET_CURRENT_SHARE_TOTAL", data);
    },
    async fetchEstimate({ commit }, { assetId, shares }) {
      const data = await OrdersUseCases.fetchEstimate({
        assetId,
        shares,
        orderSide: "SELL",
        orderType: "MARKET",
      });
      commit("SET_ESTIMATE", data);
    },
  },
  getters: {},
};

export { sell };
